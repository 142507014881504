import React, { useEffect, useMemo, useState } from 'react'
import externalStyle from '../InternetAD/InternetSelector.module.scss'
import s from './outdoorAd.module.scss'
import { Card } from 'react-bootstrap'
import { authFetch } from 'src/utils/authFetch'
import useCampaignData from 'src/stores/campaignData'
import { DistrictType } from './types'
import {
	OutdoorAdSelector,
	OutdoorLocationMethod,
	OutdoorMassCoverage,
	OutdoorAdHeader,
} from './components'
import { useAdformatsStore } from '../adformats-store/adformats.store'

export type OutdoorAdvSelectedKeys =
	| 'district_adv_is_selected'
	| 'outdoor_adv_is_selected'
export type OutdoorAdvMethods = 'district' | 'outdoor'

export const OutdoorAd = (props: any) => {
	const [campaing, campaignInterface] = useCampaignData()

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)

	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)
	const isDistrictBookingSelected = campaing.district_adv_is_selected
	const isOutdoorBookingSelected = campaing.outdoor_adv_is_selected

	const [isLoading, setIsLoading] = useState(true)
	const [districts, setDistricts] = useState<DistrictType[]>([])
	const [selectedOutdoorMethods, setSelectedOutdoorMethods] = useState<
		Array<{ [key: string]: JSX.Element }>
	>([])

	const fetchDistricts = async () => {
		const result = await authFetch<Array<DistrictType>>({
			method: 'GET',
			url: '/core/api/v1/campaign/districts',
		})
		if (result) {
			setDistricts(result)
		}
		setIsLoading(false)
	}

	//добавляет объект в начало массива или удаляет совпадающий объект
	const handleSelectorClick = async (
		selectorKey: OutdoorAdvSelectedKeys,
		isSelected: boolean
	) => {
		if (isLoading) {
			return
		}

		setIsLoading(true)

		await campaignInterface.patchCampaign(campaing.id, {
			[selectorKey]: !isSelected,
		})
		const newCamapaignState = await campaignInterface.refetchSelected()
		props.setIsLoading(false)

		const isErrorRemovingNeeded = isSelected

		if (isErrorRemovingNeeded) {
			setAdFormatErrors(
				selectorKey === 'district_adv_is_selected'
					? 'massCoverage'
					: 'garant',
				null
			)
		}

		if (
			selectorKey === 'outdoor_adv_is_selected' &&
			newCamapaignState?.booking.filter(
				(el) => el.placement.door_type === 'outdoor'
			).length === 0 &&
			newCamapaignState?.outdoor_adv_is_selected
		) {
			addNewAdFormatError('garant', {
				field: 'Не добавлены места',
				id: 'garant_id',
				message: 'Не добавлены места',
			})
		}
		const methodKey: OutdoorAdvMethods =
			selectorKey === 'district_adv_is_selected' ? 'district' : 'outdoor'

		if (selectedOutdoorMethods.some((method) => method[methodKey])) {
			const newMethods = selectedOutdoorMethods.filter(
				(method) => Object.keys(method)?.[0] !== methodKey
			)

			setSelectedOutdoorMethods(newMethods)
		} else {
			if (methodKey === 'district') {
				setSelectedOutdoorMethods((prev) => [
					{
						[methodKey]: (
							<OutdoorMassCoverage
								onError={props.onError}
								setGlobalIsLoading={props.setIsLoading}
								isLoading={isLoading}
								districts={districts}
								cn={props.cn}
								title="OutdoorCityMethod"
							/>
						),
					},
					...prev,
				])
			}
			if (methodKey === 'outdoor') {
				setSelectedOutdoorMethods((prev) => [
					{
						[methodKey]: (
							<OutdoorLocationMethod
								restProps={{ ...props }}
								cn={props.cn}
								title="Гарантированные показы"
							/>
						),
					},
					...prev,
				])
			}
		}
		setIsLoading(false)
	}

	const methods = useMemo(() => {
		return selectedOutdoorMethods.map(
			(obj) => Object.values(obj)[0]
		) as Array<JSX.Element>
	}, [selectedOutdoorMethods])

	useEffect(() => {
		fetchDistricts()
	}, [])

	useEffect(() => {
		if (isOutdoorBookingSelected) {
			setSelectedOutdoorMethods((prev) => {
				return [
					...prev,
					{
						outdoor: (
							<OutdoorLocationMethod
								restProps={{ ...props }}
								cn={props.cn}
								title="Гарантированные показы"
							/>
						),
					},
				]
			})
		}
	}, [])

	useEffect(() => {
		if (isDistrictBookingSelected && districts.length > 0) {
			setSelectedOutdoorMethods((prev) => {
				return [
					...prev,
					{
						district: (
							<OutdoorMassCoverage
								onError={props.onError}
								setGlobalIsLoading={props.setIsLoading}
								isLoading={false}
								districts={districts}
								cn={props.cn}
								title="OutdoorCityMethod"
							/>
						),
					},
				]
			})
		}
	}, [districts])
	return (
		<>
			<Card className={props.cn('form')}>
				<div className={s.outdoorCard}>
					<OutdoorAdHeader />
					<div className={externalStyle.container}>
						<OutdoorAdSelector
							onClick={handleSelectorClick}
							isSelected={isDistrictBookingSelected}
							title="Максимальный охват"
							description={
								'Распределение по свободным рекламным местам в выбранных городах'
							}
							selectorKey={'district_adv_is_selected'}
							isLoading={isLoading}
						/>

						<div>
							<OutdoorAdSelector
								onClick={handleSelectorClick}
								description={'Выбор конкретных рекламных мест'}
								isSelected={isOutdoorBookingSelected}
								title="Гарантированные показы"
								selectorKey={'outdoor_adv_is_selected'}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
			</Card>
			{methods.length > 0 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: '24px',
						gap: '24px',
					}}
				>
					{methods}
				</div>
			)}
		</>
	)
}
