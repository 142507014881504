import { BottomMessageType, PaymentMessageType } from './PaymentMessages.types'

export const PaymentMessageData: {
	[key in PaymentMessageType]?: BottomMessageType
} = {
	[PaymentMessageType.WAITING]: {
		type: PaymentMessageType.WAITING,
		leftText: 'В течении 5 минут пришлем счёт на почту',
		buttonText: 'Счет формируется',
		rightText: false,
	},
	[PaymentMessageType.GO_TO_PAYMENT]: {
		type: PaymentMessageType.GO_TO_PAYMENT,
		leftText:
			'Модерация завершена, осталось только оплатить. Также, в рекламных местах может поменятся статус брони и количество показов.',
		buttonText: 'Перейти к оплате',
		rightText: false,
	},
	[PaymentMessageType.PAYMENT_NO_CHANGES]: {
		type: PaymentMessageType.GO_TO_PAYMENT,
		leftText: 'Состояния брони и количество показов не изменились.',
		buttonText: 'Выбрать способ оплаты',
		rightText: true,
	},
	[PaymentMessageType.PAYMENT_WITH_CREATED_INVOICE]: {
		type: PaymentMessageType.PAYMENT_WITH_CREATED_INVOICE,
		leftText:
			'Счет на оплату сформирован. Оплатите его в течении 24 часов.',
		buttonText: 'Скачать счет',
		rightText: true,
	},
	[PaymentMessageType.GO_TO_ACTIVE_PAYMENT]: {
		type: PaymentMessageType.GO_TO_ACTIVE_PAYMENT,
		leftText: 'Счет на оплату сформирован. Оплатите его в течении 1 часа',
		buttonText: 'Оплатить',
		rightText: true,
	},
}
