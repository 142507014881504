import React from 'react'
import s from './selector.module.scss'
import { ReactComponent as Ok } from 'src/assets/images/ok.svg'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import { ReactComponent as MCD } from 'src/assets/images/transport.svg'
import cn from 'classnames'

type Props = {
	title: string
	selectorKey: 'center' | 'diameter'
	isSelected: boolean
	description: string
	extraInfo: string
	onClick: (selectorKey: 'center' | 'diameter') => void
	disabled
}

export const PublicTransportSelector = ({
	description,
	isSelected,
	onClick,
	selectorKey,
	title,
	disabled,
	extraInfo,
}: Props) => {
	const handleSelectorClick = () => {
		if (disabled) return
		onClick(selectorKey)
	}

	return (
		<div
			className={cn({
				[s.format_selected]: isSelected,
				[s.format]: !isSelected,
				[s.format_disabled]: disabled,
			})}
			onClick={handleSelectorClick}
		>
			<div className={cn(s.icon)}>
				{isSelected ? (
					<Ok />
				) : selectorKey === 'center' ? (
					<MCR />
				) : (
					<MCD />
				)}
			</div>
			<div className={s.title}>
				<div>{title}</div>

				<p>{description}</p>
				<p>{extraInfo}</p>
			</div>
		</div>
	)
}
