import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
	Button,
	Overlay,
	OverlayTrigger,
	Popover,
	Tooltip,
} from 'react-bootstrap'
import {
	AdFormatError,
	adFormatKeysTranslation,
	useAdformatsStore,
} from '../../../adformats-store/adformats.store'
import { getAdformatsErrorsState } from '../../../adformats-store/adformats-store-selectors/getAdformatsErrorsState'
import { RedirectErrorItem } from './RedirectErrorItem'
import { ReactComponent as Warning } from 'src/assets/images/warningRing.svg'
import cn from 'classnames'
import s from './errorDetailsButton.module.scss'

const errorTitleByCount = (errorsCount: number): string => {
	if (errorsCount % 10 === 1 && errorsCount % 100 !== 11) {
		return `${errorsCount} Ошибка`
	} else if (
		[2, 3, 4].includes(errorsCount % 10) &&
		![12, 13, 14].includes(errorsCount % 100)
	) {
		return `${errorsCount} Ошибки`
	} else {
		return `${errorsCount} Ошибок`
	}
}

type Props = {
	isAdaptive?: boolean
}
export const ErrorsDetailsButton = ({ isAdaptive }: Props) => {
	const errors = useAdformatsStore(getAdformatsErrorsState)
	const [errorsListOpen, setErrorsListOpen] = useState(false)
	const errorsCount = useMemo(() => {
		return Object.values(errors).reduce((acc, curr) => {
			return acc + (curr ? curr.length : 0)
		}, 0)
	}, [errors])

	const [isScrolling, setIsScrolling] = useState(false)

	const RedirectToErrorList = useMemo(() => {
		const currentErrors = Object.keys(errors).filter(
			(key) => errors[key]?.length
		)

		const renderList = currentErrors.map((key) => {
			const adError: AdFormatError[] = errors[key]
			return (
				<RedirectErrorItem
					key={key}
					adErrors={adError}
					adName={adFormatKeysTranslation[key]}
				/>
			)
		})

		return (
			<Popover
				id="step2_errors_overlay"
				style={{
					background: 'white',
					boxShadow: '0px 0px 20px rgba(33, 37, 41, 0.1)',
					border: 'none',
					minWidth: '300px',
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
					padding: '0.5rem',
					zIndex: 111,
					position: 'fixed',
				}}
			>
				{renderList}
			</Popover>
		)
	}, [errors])

	const toggleErrorsList = () => {
		setErrorsListOpen((prev) => !prev)
	}
	useEffect(() => {
		if (!isAdaptive) {
			return
		}
		let scrollTimer: NodeJS.Timeout | null = null
		const handleScroll = () => {
			if (!isScrolling) {
				setIsScrolling(true)
				setErrorsListOpen(false)
			}

			if (scrollTimer !== null) {
				clearTimeout(scrollTimer)
			}

			scrollTimer = setTimeout(() => {
				setIsScrolling(false)
			}, 150)
		}

		window.document.addEventListener('scroll', handleScroll)
		return () => {
			window.document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<div
			className={cn(s.errorsButton, {
				[s.adaptiveErrorButton]: isAdaptive,
			})}
			style={{ display: errorsCount === 0 ? 'none' : 'block' }}
		>
			<OverlayTrigger
				key={'top'}
				placement={'top'}
				overlay={RedirectToErrorList}
				show={errorsListOpen && !isScrolling}
				popperConfig={{ strategy: 'fixed' }}
			>
				<Button variant={'danger'} onClick={toggleErrorsList}>
					<Warning />
					<span>{errorTitleByCount(errorsCount)}</span>
				</Button>
			</OverlayTrigger>
		</div>
	)
}

export const RedirectErrosPopover = React.memo(
	(props: { errorsList: JSX.Element[] }) => {
		return (
			<Popover
				id="step2_errors_overlay"
				style={{
					background: 'white',
					boxShadow: '0px 0px 20px rgba(33, 37, 41, 0.1)',
					border: 'none',
					minWidth: '300px',
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
					padding: '0.5rem',
					zIndex: 111,
					position: 'fixed',
				}}
			>
				{props}
			</Popover>
		)
	}
)
RedirectErrosPopover.displayName = 'RedirectErrosPopover'
