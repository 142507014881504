import React, { useState } from 'react'
import s from '../ClientStatistics.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { CampaignBadge, getStartEndString } from '../index'
import { ReactComponent as AddIcon } from 'src/assets/images/addd.svg'
import { ReactComponent as MoreIcon } from 'src/assets/images/more-vert.svg'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'
import { ExpandedMobileStatistic } from './expanded-mobile-statistic/ExpandedMobileStatistic'
import { Spinner } from 'react-bootstrap'
import { ReactComponent as Minus } from 'src/assets/images/minus-bordered.svg'

type Props = {
	isBorder: boolean
	item: any
	onCampaignClick: (campaignId: string, state: CampaignStates) => void
	onModalOpen: (campaignId: string) => void
}

export const MobileStatisticItem = ({
	item,
	onCampaignClick,
	onModalOpen,
	isBorder,
}: Props) => {
	const [detailedCampaign, setDetailedCampaign] = useState<any | undefined>(
		undefined
	)
	const [isLoading, setIsLoading] = useState(false)

	const fetchDetailed = async (campaignId: string) => {
		return await authFetch<any>({
			url: `${BASE_URL}/adv_companies/${campaignId}/stats/`,
			method: 'GET',
		})
	}
	const handleIsExpanded = async (campaignId: string) => {
		if (detailedCampaign) {
			setDetailedCampaign(undefined)
		} else {
			setIsLoading(true)
			const campaignDetailedInfo = await fetchDetailed(campaignId)
			setDetailedCampaign(campaignDetailedInfo)
			setIsLoading(false)
		}
	}

	return (
		<div style={{ borderBottom: isBorder ? '1px solid #CED4DA' : '' }}>
			<React.Fragment key={item.id}>
				<div key={item.id} className={s.mobileCampaign}>
					<div className={s.mobileHeaderFrame}>
						<h1
							onClick={() => onCampaignClick(item.id, item.state)}
							className={s.mobileHeaderFrameText}
						>
							{item.name}
						</h1>
						<div className={s.mobileAddIconOneFrame}>
							<div
								className={s.mobileAddIconTwoFrame}
								onClick={() =>
									handleIsExpanded(item?.id || '0')
								}
							>
								{detailedCampaign ? (
									<Minus className={s.mobileADD} />
								) : (
									<AddIcon className={s.mobileADD} />
								)}
							</div>
						</div>
					</div>

					<div className={s.mobileLineOneFrame}>
						<h1>Период:</h1>
						<h2>
							{getStartEndString(item.start_date, item.end_date)}
						</h2>
					</div>

					<div className={s.mobileLineTwoFrame}>
						<h1>Регион показа:</h1>
						<h2>{item.extra_data.region_titles[0]}</h2>
					</div>

					<div className={s.mobileLineThreeFrame}>
						<h1>Пол:</h1>
						<h2>{item.extra_data.gender_titles}</h2>
					</div>

					<div className={s.mobileLineFourFrame}>
						<h1>Возраст:</h1>
						<h2
							style={{
								maxWidth: '100%',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
						>
							{item.extra_data.age_titles.join(', ')}
						</h2>
					</div>

					<div className={s.mobileLineFiveFrame}>
						<h1>Бюджет:</h1>
						<h2>
							{IntlFormatter.format(item.extra_data.total_budget)}
						</h2>
					</div>

					<div className={s.mobileFooter}>
						<div className={s.mobileStateDiv}>
							<CampaignBadge state={item.state} />
						</div>

						<div className={s.mobileMoreIconDiv}>
							<MoreIcon onClick={() => onModalOpen(item.id)} />
						</div>
					</div>

					{/* {index !== oneData.length - 1 && <span />} */}
				</div>
			</React.Fragment>

			{isLoading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '1rem',
					}}
				>
					<Spinner animation="border" size={'sm'} variant="primary" />
				</div>
			)}
			{detailedCampaign && (
				<ExpandedMobileStatistic
					isLoading={isLoading}
					detailedCampaign={detailedCampaign}
				/>
			)}
		</div>
	)
}
