import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import useCampaignData from '../../../../stores/campaignData'
import { Button, Card, Spinner } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import s from './ADFormatSummary.module.scss'
import useResponsive from '../../../../utils/useResponsive'
import BookingAD from './BookingsAD/BookingAD'
import InternetSelector from './InternetAD/InternetSelector'
import Budget from './Budget'
import TVSelector from './TVAd/TVSelector'
import { ToastsContext } from '../../../UtilityComponents/ToastsContextProvider'
import YandexDirectAd from './InternetAD/YandexDirectAd'
import VkAd from './InternetAD/VkAd'
import TVvideo from './TVAd/TVvideo'
import TVbanner from './TVAd/TVbanner'
import AmountNotZero from '../../../../utils/AmountNotZero'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import { OutdoorAd } from './outdoor-ad'

import { PublicTransportAd } from './public-transport/PublicTransportAd'
import { useAdformatsStore } from './adformats-store/adformats.store'
import { ErrorsDetailsButton } from './common/components/error-details-button/ErrorsDetailsButton'
import { ConfirmBudgetButton } from './common/components/confirm-budget-button/ConfirmBudgetButton'

const insert = (arr, index, newItem) => [
	...arr.slice(0, index),
	newItem,
	...arr.slice(index),
]

const cardsAnimateVariants = {}

const cardTransition = {
	type: 'ease-in-out',
	duration: 0.4,
}
export type InsideErrors = {
	massCoverage: boolean
	outdoor: boolean
	indoor: boolean
	yandex: boolean
	vk: boolean
	adstream: boolean
	publicTransport: boolean
}
const AdFormatSummary = ({ cn, onBack, onNextStep }) => {
	const [panelShowing, setPanelShowing] = useState(true)
	const actionsPanelRef = useRef<HTMLDivElement | null>(null)
	const budgRef = useRef<HTMLDivElement | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		const handleIntersection = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries
			if (!entry.isIntersecting) {
				setPanelShowing(true)
			} else {
				setPanelShowing(false)
			}
		}

		const actionsPanelObserver = new IntersectionObserver(
			handleIntersection,
			{
				rootMargin: '-100px 0px 0px 0px',
			}
		)

		const setObserver = () => {
			if (actionsPanelRef.current && budgRef.current) {
				actionsPanelObserver.observe(budgRef.current)
			} else {
				setTimeout(setObserver, 100)
			}
		}

		setObserver()

		return () => {
			actionsPanelObserver.disconnect()
		}
	}, [])

	const [campaign, campaignInterface] = useCampaignData()

	const initilizeSelectedState = useAdformatsStore(
		(state) => state.initilizeSelectedState
	)

	useEffect(() => {
		initilizeSelectedState(campaign)
	}, [])
	const { addToast } = useContext(ToastsContext)
	const { isMobile } = useResponsive()
	const [adFormats, setAdFormats] = useState<any>(() => [
		{
			title: 'Наружная реклама',
			isSelected: campaign?.public_transport?.id,
			Component: BookingAD,
			key: 'outdoor',
			data: [],
		},
		{
			title: 'Внутренняя реклама',
			isSelected: campaign?.indoor_adv_is_selected,
			Component: BookingAD,
			key: 'indoor',
			data: [],
		},
		{
			title: 'Общественный транспорт',
			isSelected: campaign?.public_transport?.id,
			Component: PublicTransportAd,
			key: 'publicTransport',
			data: [],
		},

		{
			title: 'Интернет-реклама',
			isSelected:
				campaign?.internet_yandex?.is_selected ||
				campaign?.internet_vk?.is_selected,
			key: 'internet',
			Component: InternetSelector,
			data: [
				{
					key: 'yandex',
					isSelected: campaign?.internet_yandex?.is_selected,
					title: 'Яндекс',
					description:
						'Авито, Mail.ru, Яндекс.Новости, Яндекс.Дзен, Яндекс.Спорт, Gismeteo и другие',
					disabled: false,
					Component: YandexDirectAd,
				},
				{
					key: 'vk',
					isSelected: campaign?.internet_vk?.is_selected,
					title: 'VK',
					description:
						'ВКонтакте, Одноклассники, Юла, Mail.ru, MyTarget',
					disabled: false,
					Component: VkAd,
				},
			],
		},
		{
			title: 'ТВ-реклама',
			isSelected:
				campaign?.teletarget?.is_selected ||
				campaign.adstream?.is_selected,
			key: 'tv',
			Component: TVSelector,
			data: [
				{
					key: 'video',
					isSelected: campaign.adstream?.is_selected,
					title: 'Показ видеоролика при переключении канала',
					disabled: false,
					Component: TVvideo,
				},
				{
					key: 'banner',
					isSelected: campaign?.teletarget?.is_selected,
					title: 'Показ всплывающего баннера при просмотре канала',
					disabled: false,
					Component: TVbanner,
				},
			],
		},
	])
	const [stack, setStack] = useState<{ Component: any; key: string }[]>(() =>
		getStackedFormats(campaign)
	)

	const internetFormats = useMemo(() => {
		if (!adFormats) return []
		return adFormats.find((el) => el.key === 'internet')?.data
	}, [adFormats])
	const tvFormats = useMemo(() => {
		if (!adFormats) return []
		return adFormats.find((el) => el.key === 'tv')?.data
	}, [adFormats])

	const [insideErrors, setInsideErrors] = useState<InsideErrors>({
		massCoverage: false,
		outdoor: false,
		indoor: false,
		yandex: false,
		vk: false,
		adstream: false,
		publicTransport: false,
	})

	const handleSetInsideError = (key: keyof InsideErrors, value: boolean) => {
		setInsideErrors((prev) => ({ ...prev, [key]: value }))
	}

	const isButtonDis = useMemo(() => {
		const isFirstCampaignSelected =
			(campaign?.outdoor_adv_is_selected ? 1 : 0) +
				(campaign?.district_adv_is_selected ? 1 : 0) +
				(campaign?.public_transport?.is_selected ? 1 : 0) +
				(campaign?.indoor_adv_is_selected ? 1 : 0) +
				(campaign?.internet_yandex?.is_selected ? 1 : 0) +
				(campaign?.internet_vk?.is_selected ? 1 : 0) +
				(campaign?.teletarget?.is_selected ? 1 : 0) +
				(campaign?.adstream?.is_selected ? 1 : 0) ===
			1

		const isAtLeastOneCampaignSelected =
			campaign?.outdoor_adv_is_selected ||
			campaign?.district_adv_is_selected ||
			campaign?.indoor_adv_is_selected ||
			campaign?.internet_yandex?.is_selected ||
			campaign?.internet_vk?.is_selected ||
			campaign?.teletarget?.is_selected ||
			campaign?.public_transport?.is_selected ||
			campaign?.adstream?.is_selected

		if (!isAtLeastOneCampaignSelected) {
			// console.log('No campaigns selected');
			return false
		} else if (isFirstCampaignSelected) {
			// console.log('First campaign selected');

			let isFirstCampaignFieldsValid = false
			if (campaign?.outdoor_adv_is_selected) {
				isFirstCampaignFieldsValid =
					campaign.outdoor_bookings.length !== 0
			} else if (campaign?.district_adv_is_selected) {
				isFirstCampaignFieldsValid =
					campaign.districtbookings.length !== 0
			} else if (campaign?.indoor_adv_is_selected) {
				isFirstCampaignFieldsValid =
					campaign.indoor_bookings.length !== 0
			} else if (campaign?.internet_yandex?.is_selected) {
				isFirstCampaignFieldsValid =
					AmountNotZero(campaign.internet_yandex.amount) &&
					campaign.internet_yandex.link != null
			} else if (campaign?.internet_vk?.is_selected) {
				isFirstCampaignFieldsValid =
					AmountNotZero(campaign.internet_vk.amount) &&
					campaign.internet_vk.link !== null
			} else if (campaign?.teletarget?.is_selected) {
				isFirstCampaignFieldsValid = AmountNotZero(
					campaign.teletarget.amount
				)
			} else if (campaign?.adstream?.is_selected) {
				isFirstCampaignFieldsValid = AmountNotZero(
					campaign.adstream.amount
				)
			} else if (campaign?.public_transport?.is_selected) {
				isFirstCampaignFieldsValid = AmountNotZero(
					campaign.public_transport.amount
				)
			}

			return isFirstCampaignFieldsValid
		} else {
			// console.log('Multiple campaigns selected');

			const isFieldsValid =
				(!campaign?.outdoor_adv_is_selected ||
					campaign.outdoor_bookings.length !== 0) &&
				(!campaign?.district_adv_is_selected ||
					campaign.districtbookings.length !== 0) &&
				(!campaign?.indoor_adv_is_selected ||
					campaign.indoor_bookings.length !== 0) &&
				(!campaign?.internet_yandex?.is_selected ||
					(AmountNotZero(campaign.internet_yandex.amount) &&
						campaign.internet_yandex.link != null)) &&
				(!campaign?.internet_vk?.is_selected ||
					(AmountNotZero(campaign.internet_vk.amount) &&
						campaign.internet_vk.link !== null)) &&
				(!campaign?.teletarget?.is_selected ||
					AmountNotZero(campaign.teletarget.amount)) &&
				(!campaign?.adstream?.is_selected ||
					AmountNotZero(campaign.adstream.amount)) &&
				(!campaign?.public_transport?.is_selected ||
					AmountNotZero(campaign.public_transport.amount))

			return isFieldsValid
		}
	}, [campaign])

	const isBudgetShowing = useMemo(
		() =>
			(campaign?.outdoor_adv_is_selected &&
				campaign.outdoor_bookings.length !== 0) ||
			(campaign?.district_adv_is_selected &&
				campaign.districtbookings.length !== 0) ||
			(campaign?.indoor_adv_is_selected &&
				campaign.indoor_bookings.length !== 0) ||
			(campaign?.internet_yandex?.is_selected &&
				AmountNotZero(campaign.internet_yandex.amount)) ||
			(campaign?.internet_vk?.is_selected &&
				AmountNotZero(campaign.internet_vk.amount)) ||
			(campaign?.teletarget?.is_selected &&
				AmountNotZero(campaign.teletarget.amount)) ||
			(campaign?.adstream?.is_selected &&
				AmountNotZero(campaign.adstream.amount)) ||
			(campaign?.public_transport?.is_selected &&
				AmountNotZero(campaign.public_transport.amount)),
		[campaign]
	)

	useEffect(() => {
		document.body.style.overflowY = 'overlay'
		return () => {
			document.body.style.overflowY = ''
		}
	}, [])

	const onSubmit = async () => {
		setIsLoading(true)
		if (!campaign?.is_budget_confirmed) {
			try {
				await campaignInterface.confirmBudget(campaign.id)
				addToast({
					text: 'Ваш итоговый бюджет подтвержден',
					type: 'primary',
				})
			} catch {
				addToast({
					text: 'Что-то пошло не так...',
					type: 'danger',
				})
			} finally {
				setIsLoading(false)
			}
		}

		onNextStep()
	}

	const stackElements = useMemo(() => {
		return stack.map(({ Component, key }, index) => {
			// раньше было 2 формата tv: video и banner (adstream и teletarget)
			// сейчас оставили один, и при выборе/удалении tv
			// дополнительно делаем выбору/удаление video в AdFormatSelector
			if (key === 'tv') return null

			if (key === 'outdoor' || key === 'publicTransport') {
				return (
					<div id={key} key={key + 'AD'}>
						<Component
							cn={cn}
							onError={handleSetInsideError}
							setIsLoading={setIsLoading}
							bookings_type={key}
							setFormats={setAdFormats}
							addToStack={(item, afterKey = '') => {
								if (!afterKey) {
									setStack((p) => [item, ...p])
								} else {
									//find index
									const index = stack.findIndex(
										(el) => el.key === afterKey
									)
									if (index !== undefined) {
										setStack((p) =>
											insert(p, index + 1, item)
										)
									}
								}
							}}
							removeFromStack={(key) =>
								setStack((p) => p.filter((a) => a.key !== key))
							}
						/>
					</div>
				)
			} else {
				return (
					<div
						id={key}
						key={key + 'AD'}
						style={{ zIndex: 11 - index }}
					>
						<Card className={cn('form')}>
							<Component
								cn={cn}
								onError={handleSetInsideError}
								setIsLoading={setIsLoading}
								bookings_type={key}
								formats={
									key === 'tv' ? tvFormats : internetFormats
								}
								setFormats={setAdFormats}
								addToStack={(item, afterKey = '') => {
									if (!afterKey) {
										setStack((p) => [item, ...p])
									} else {
										//find index
										const index = stack.findIndex(
											(el) => el.key === afterKey
										)
										if (index !== undefined) {
											setStack((p) =>
												insert(p, index + 1, item)
											)
										}
									}
								}}
								removeFromStack={(key) =>
									setStack((p) =>
										p.filter((a) => a.key !== key)
									)
								}
							/>
						</Card>
					</div>
				)
			}
		})
	}, [
		stack,
		cn,
		setAdFormats,
		setInsideErrors,
		tvFormats,
		internetFormats,
		cardTransition,
		cardsAnimateVariants,
	])

	useEffect(() => {
		if (campaign) {
			setStack(getStackedFormats(campaign))
		}
	}, [])

	return (
		<div className={s.container}>
			{stackElements}

			{/* всплывашка продолжить/подтвердить  */}
			{panelShowing && (
				<>
					<AnimatePresence exitBeforeEnter>
						{isBudgetShowing && (
							<motion.div
								className={s.actionsPanel}
								initial={{ opacity: 0, y: 20 }}
								animate={{
									opacity: isBudgetShowing ? 1 : 0,
									y: isBudgetShowing ? 0 : 20,
								}}
								exit={{ opacity: 0, y: 20 }}
								transition={{ duration: 0.7 }}
								ref={actionsPanelRef}
							>
								<h1 className={s.itoGo}>
									Итого по всем типам рекламы:{' '}
									{IntlFormatter.format(
										campaign.budget.total
									)}
								</h1>

								<div className={s.btnApprove}>
									<ErrorsDetailsButton isAdaptive={true} />
									<ConfirmBudgetButton
										isBudgetConfirmed={
											campaign?.is_budget_confirmed
										}
										isButtonsDisabled={isButtonDis}
										isLoading={isLoading}
										onSubmit={onSubmit}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</>
			)}

			{/* панель расчета стоимости рекламы */}
			<div ref={budgRef}>
				{isBudgetShowing && (
					<motion.div
						key={'budget'}
						layout
						variants={cardsAnimateVariants}
						animate={'animate'}
						initial={'initial'}
						exit={'exit'}
						transition={cardTransition}
						// style={{ zIndex: 12 }}
					>
						<Card className={cn('form')}>
							<Budget />
						</Card>
					</motion.div>
				)}
			</div>

			{/* панель перехода/возврата шага */}
			<motion.div className={s.controls}>
				<div
					style={{
						display: 'flex',
						gap: isMobile ? '0.5rem' : '1rem',
						flexDirection: isMobile ? 'column' : 'row',
					}}
				>
					<ErrorsDetailsButton isAdaptive={false} />
					<ConfirmBudgetButton
						isBudgetConfirmed={campaign?.is_budget_confirmed}
						isButtonsDisabled={isButtonDis}
						isLoading={isLoading}
						onSubmit={onSubmit}
					/>
				</div>

				<Button variant={'secondary'} onClick={onBack}>
					Назад
				</Button>
			</motion.div>
		</div>
	)
}

function getStackedFormats(campaign: CampaignInfoTypeBookingsSeparation) {
	const stack: {
		Component: any
		key: string
	}[] = []

	stack.push({
		key: 'outdoor',
		Component: OutdoorAd,
	})
	stack.push({
		key: 'indoor',
		Component: BookingAD,
	})

	stack.push({
		key: 'publicTransport',
		Component: PublicTransportAd,
	})

	stack.push({
		key: 'internet',
		Component: InternetSelector,
	})

	if (campaign.internet_yandex.is_selected)
		stack.push({
			key: 'yandex',
			Component: YandexDirectAd,
		})
	if (campaign.internet_vk.is_selected)
		stack.push({
			key: 'vk',
			Component: VkAd,
		})

	// if (campaign.teletarget.is_selected) {
	// 	stack.push({
	// 		key: 'banner',
	// 		Component: TVbanner,
	// 		isHiddenContent: campaign.teletarget.is_selected,
	// 	})
	// }
	stack.push({
		key: 'video',
		Component: TVvideo,
	})
	return stack
}

export default AdFormatSummary
