import React from 'react'
import { AdIcon } from '../../../AdFormatSelector'
import s from './oudoorHeader.module.scss'

export const OutdoorAdHeader = (props: any) => {
	return (
		<div className={s.outdoorHeader}>
			<div className={s.primaryText}>
				<h4>Наружная реклама</h4>
				<div>
					<AdIcon name={'outdoor'} />
				</div>
			</div>
			<p>Выберите способ размещения</p>
		</div>
	)
}
