import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Dropdown, Spinner } from 'react-bootstrap'
import { getPlacementInfo } from '../../../../../../../constants/api'
import {
	PlacementProvider,
	PlacementType,
} from '../../../../../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../../../../../stores/campaignData'
import { authFetch } from '../../../../../../../utils/authFetch'
import declOfNum from '../../../../../../../utils/declOfNum'
import DetailItem from '../../../../../../../utils/DetailItem'
import IntlFormatter from '../../../../../../../utils/intl-money-formatter'
import useResponsive from '../../../../../../../utils/useResponsive'
import ImageFullScreenWrapper from '../../../../../../UtilityComponents/ImageFullScreenWrapper'
import {
	PlacementShortWithStateAndFocus,
	PlacementWithOnlyOneShowingCount,
} from '../../BookingAD'
import { ReactComponent as Warning } from 'src/assets/images/warningRing.svg'
import { motion } from 'framer-motion'
import { formattedNumber } from 'src/utils'
import { VideoDurationSelector } from '../VideoDurationSelector'
import { ShowingCountTypeSelector } from '../ShowingCountTypeSelector'
import { PlacementDetatilsFooter } from './PlacementDetatilsFooter'
import s from './placementDetail.module.scss'
import { PlacementsDetailsCommonList } from './PlacementsDetailsCommonList'

interface IPlacementDetails {
	placements: PlacementShortWithStateAndFocus[]
	placementID: number
	onBack: () => void
	handleSelectPlacement: (
		id: number,
		placement_details: PlacementWithOnlyOneShowingCount
	) => void
	handleRemovePlacement: (id: number) => void
}

const PlacementDetails: FC<IPlacementDetails> = ({
	placementID,
	placements,
	onBack,
	handleRemovePlacement,
	handleSelectPlacement,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [details, setDetails] = useState<null | PlacementType>(null)
	const [fetchError, setFetchError] = useState<boolean>(false)
	const [selectedPrice, setSelectedPrice] = useState<number>()
	const { isMobile } = useResponsive()

	const booked = useMemo<boolean>(() => {
		if (!placementID) return false
		const current = placements.find((p) => p.pk === placementID)
		console.log('current', current)

		if (current) return current.state === 'selected'
		return false
	}, [placementID, placements])

	const isProviderFeature =
		details &&
		(details.provider.booking_cancel || details.provider.booking_features)

	useEffect(
		() => {
			;(async () => {
				if (placementID === details?.id) return
				setDetails(null)
				setFetchError(false)
				try {
					const response: PlacementType = await authFetch({
						url: getPlacementInfo.params(campaign.id, placementID),
						method: 'GET',
					})
					setDetails(response)
					setSelectedPrice(response.placement_showing_counts?.[0]?.id)
				} catch (e: any) {
					setFetchError(true)
				} finally {
				}
			})()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[placementID]
	)

	//Loading
	if (details === null && !fetchError)
		return <Loading onBack={onBack} isMobile={isMobile} />
	//Error
	if (details === null && fetchError)
		return <Error onBack={onBack} isMobile={isMobile} />

	if (details)
		return (
			<div
				className={'d-flex flex-column'}
				style={{ height: isMobile ? 'auto' : '100%' }}
			>
				{/*BackButton*/}
				{!isMobile && (
					<div className={'p-3'}>
						<div>
							<a
								href={'/'}
								className={'text-secondary'}
								onClick={(e) => {
									e.preventDefault()
									onBack()
								}}
							>
								<i className={'bi bi-arrow-left'} /> Назад
							</a>
						</div>
					</div>
				)}

				{/*PlacementDetails*/}
				<div style={{ flex: '1 1 auto', overflowY: 'auto' }}>
					<div
						style={{
							maxWidth: '100%',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<ImageFullScreenWrapper>
							<img
								src={
									details.image ||
									'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
								}
								alt={details.name}
								style={{
									objectFit: 'cover',
									width: '100%',
									height: '240px',
									margin: '0 auto',
								}}
							/>
						</ImageFullScreenWrapper>
					</div>
					<div className={s.placementsInfoList}>
						<PlacementsDetailsCommonList details={details} />

						{!booked && details.door_type === 'indoor' && (
							<DetailItem
								styles={{ alignItems: 'center' }}
								left={`Длительность показа (в секундах)`}
								right={
									<VideoDurationSelector
										showingCounts={
											details.placement_showing_counts
										}
										selectedPrice={selectedPrice}
										setSelectedPrice={setSelectedPrice}
										isActive={details.is_active}
									/>
								}
								bottomDivider={false}
							/>
						)}

						{isProviderFeature && (
							<PrividerFeatures provider={details.provider} />
						)}

						{!booked && details.door_type === 'outdoor' && (
							<ShowingCountTypeSelector
								details={details}
								selectedPrice={selectedPrice}
								setSelectedPrice={setSelectedPrice}
							/>
						)}
					</div>
				</div>

				{/*ControlButton*/}

				<PlacementDetatilsFooter
					booked={booked}
					details={details}
					onRemovePlacement={handleRemovePlacement}
					onSelectPlacement={handleSelectPlacement}
					selectedPrice={selectedPrice}
				/>
			</div>
		)

	return <></>
}

export default PlacementDetails

const Loading = ({ onBack, isMobile }) => (
	<div className={'d-flex justify-content-center align-items-center h-100'}>
		<Spinner animation={'border'} variant={'primary'} />
		{!isMobile && (
			<div className={'position-absolute top-0 left-0 w-100 p-3'}>
				<div>
					<a
						href={'/'}
						className={'text-secondary'}
						onClick={(e) => {
							e.preventDefault()
							onBack()
						}}
					>
						<i className={'bi bi-arrow-left'} /> Назад
					</a>
				</div>
			</div>
		)}
	</div>
)

const Error = ({ onBack, isMobile }) => (
	<div className={'d-flex justify-content-center align-items-center h-100'}>
		{!isMobile && (
			<div className={'position-absolute top-0 left-0 w-100 p-3'}>
				<div>
					<a
						href={'/'}
						className={'text-secondary'}
						onClick={(e) => {
							e.preventDefault()
							onBack()
						}}
					>
						<i className={'bi bi-arrow-left'} /> Назад
					</a>
				</div>
			</div>
		)}

		<p className={'text-center px-3'}>
			Произошла ошибка при загрузке данных наружного места.
		</p>
	</div>
)

export const DisplayPlacementPriceString = (
	showing_count_per_day: number,
	price_per_day: string
): string => {
	if (!showing_count_per_day || !price_per_day) return 'Ошибка в расчете цены'
	return `${showing_count_per_day} ${declOfNum(showing_count_per_day, [
		'показ',
		'показа',
		'показов',
	])} в день`
}

export const DisplayInnerPlacementPriceString = (
	screen_count: number,
	price_per_day: string
): string => {
	if (!screen_count || !price_per_day) return 'Ошибка в расчете цены'
	return `${IntlFormatter.format(
		price_per_day
	)} за ${screen_count} ${declOfNum(screen_count, [
		'экран',
		'экрана',
		'экранов',
	])} в день`
}

type ProviderFeatureType = {
	provider: PlacementProvider
}

export const PrividerFeatures = ({ provider }: ProviderFeatureType) => {
	const [open, setOpen] = useState(true)

	return (
		<div
			style={{
				padding: '0.5rem',
				margin: '0.5rem 0',
				borderRadius: '8px',
				backgroundColor: 'rgb(8, 109, 221,0.1)',
				display: 'flex',
				flexDirection: 'column',
				gap: open ? '0.5rem' : 0,
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '0.5rem',
					}}
				>
					<Warning
						style={{ minWidth: '24px', minHeight: '24px' }}
						fill="#ffc107"
					/>
					<h6 style={{ margin: 0 }}>Дополнительно</h6>
				</div>
				<Button onClick={() => setOpen(!open)} variant="text">
					{open ? 'Скрыть' : 'Показать'}
				</Button>
			</div>

			<motion.div
				initial={false}
				animate={{
					height: open ? 'auto' : 0,
					opacity: open ? 1 : 0,
				}}
				transition={{ duration: 0.3 }}
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '0.5rem',
				}}
			>
				{provider.booking_cancel && (
					<div>- {provider.booking_cancel}</div>
				)}
				{provider.booking_features && (
					<div>- {provider.booking_features}</div>
				)}
			</motion.div>
		</div>
	)
}
