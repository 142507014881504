import React from 'react'
import { Button } from 'react-bootstrap'
import s from './addedItemsControl.module.scss'
type Props = {
	itemsCount: number
	onSubmit: () => void
}
const minAddedItemsCount = 5
export const RightPanelFooter = ({ itemsCount, onSubmit }: Props) => {
	return (
		<div className={s.rightFooterWrapper}>
			<div className={s.rightFooter}>
				<div className={s.rightFooterPrimaryWrapper}>
					<div className={s.rightFooterPrimary}>
						<div style={{ margin: 0 }}>Всего мест добавлено:</div>
						<span>{itemsCount}</span>
					</div>
					<p
						className="text-danger"
						style={{
							display:
								itemsCount < minAddedItemsCount
									? 'block'
									: 'none',
						}}
					>{`Минимальное количество добавленных мест: ${minAddedItemsCount}`}</p>
				</div>

				<Button
					variant="primary"
					onClick={onSubmit}
					disabled={itemsCount < minAddedItemsCount}
				>
					Подтвердить выбор
				</Button>
			</div>
		</div>
	)
}
