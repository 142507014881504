import React, { Children } from 'react'
import { ReactComponent as OK } from '../../../../../assets/images/ok.svg'
import { ReactComponent as Error } from '../../../../../assets/images/clear-ring.svg'
import { ReactComponent as Warning } from '../../../../../assets/images/warningRing.svg'

export type FormatConfExactProps = {
	isValid: boolean
	isWarning?: boolean
	children?: React.ReactNode
	warningText?: string
}
export const FormatConfExact = ({
	isValid,
	children,
	isWarning = false,
	warningText = 'Заполнены не все поля',
}: FormatConfExactProps) => {
	const text = isWarning
		? warningText
		: isValid
		? 'Добавлено в рекламную кампанию'
		: 'Проверьте правильность заполненных  полей'

	return (
		<div
			style={{
				display: 'flex',
				padding: '16px ',
				gap: '16px',
				background: isWarning
					? '#fff3cd'
					: isValid
					? 'rgb(40 167 69 / 10%)'
					: 'rgb(220 53 69 / 10%)',
				borderRadius: '8px',
				alignItems: 'center',
				justifyContent: 'flex-start',
			}}
		>
			{isWarning ? (
				<Warning
					style={{ minWidth: '24px', minHeight: '24px' }}
					fill="#ffc107"
				/>
			) : isValid ? (
				<OK style={{ fill: '#28A745' }} />
			) : (
				<Error />
			)}
			<p
				style={{
					color: isWarning
						? '#856404'
						: isValid
						? '#28A745'
						: '#dc3545',
					padding: 0,
					margin: 0,
				}}
			>
				{text} {children}
			</p>
		</div>
	)
}
