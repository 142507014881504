import React from 'react'
import { ReactComponent as LocationPlacemarker } from 'src/assets/images/location-3.svg'
import { ReactComponent as City } from 'src/assets/images/radius.svg'
import { ReactComponent as Ok } from 'src/assets/images/ok.svg'
import cn from 'classnames'
import s from './outdoorSelector.module.scss'
import { OutdoorAdvSelectedKeys } from '../..'

type OutdoorAdSelectorProps = {
	title: string
	selectorKey: OutdoorAdvSelectedKeys
	isSelected: boolean
	description: string
	onClick: (selectorKey: OutdoorAdvSelectedKeys, isSelected: boolean) => void
	isLoading: boolean
}
export const OutdoorAdSelector = ({
	title,
	selectorKey,
	isSelected,
	description,
	onClick,
	isLoading,
}: OutdoorAdSelectorProps) => {
	return (
		<div
			className={cn({
				[s.format_selected]: isSelected,
				[s.format]: !isSelected,
				[s.format_disabled]: isLoading,
			})}
			onClick={() => onClick(selectorKey, isSelected)}
		>
			<div className={cn(s.icon)}>
				{isSelected ? (
					<Ok />
				) : selectorKey === 'district_adv_is_selected' ? (
					<City />
				) : (
					<LocationPlacemarker />
				)}
			</div>
			<div className={s.title}>
				<div>{title}</div>
				<span>{description}</span>
			</div>
		</div>
	)
}
