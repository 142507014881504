import React, { FC, useMemo } from 'react'
import useCampaignData from '../../../stores/campaignData'
import {
	CampaignBudgetIcon,
	CampaignDurationIcon,
	CampaignStartEndIcon,
} from './icons/CampaignDurationsIcons'
import useResponsive from '../../../utils/useResponsive'
import declOfNum from '../../../utils/declOfNum'
import intlMoneyFormatter from '../../../utils/intl-money-formatter'
import s from './CampaignInfo.module.scss'
import { Dropdown } from 'react-bootstrap'

type DataType = {
	icon: FC
	title: string
	text: string
	showTitle: boolean
}

const CampaignDuration: FC = () => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()

	const data = useMemo<DataType[]>(() => {
		return [
			{
				icon: CampaignStartEndIcon,
				title: 'Дата начала и окончания:',
				text: campaignInterface.getCampaignPeriod(),
				showTitle: !isMobile,
			},
			{
				icon: CampaignDurationIcon,
				title: 'Срок размещения:',
				text: `${campaign.days_duration} ${declOfNum(
					campaign.days_duration,
					['день', 'дня', 'дней']
				)}`,
				showTitle: true,
			},
			{
				icon: CampaignBudgetIcon,
				title: 'Итоговый бюджет:',
				text: intlMoneyFormatter.format(campaign.budget.total),
				showTitle: true,
			},
		]
	}, [campaign, isMobile]) // eslint-disable-line react-hooks/exhaustive-deps

	if (isMobile)
		return (
			<div className={s.campaignDurationContainerMobile}>
				{data.map((d, index) => (
					<React.Fragment key={d.title}>
						<div>
							<div
								style={{
									display: 'grid',
									placeItems: 'center',
								}}
							>
								<d.icon />
							</div>
							<div className={'ms-2 w-100 text-dark'}>
								{!d.showTitle ? (
									d.text
								) : (
									<div
										className={
											'w-100 d-flex justify-content-between'
										}
									>
										<span>{d.title}</span>
										<span>{d.text}</span>
									</div>
								)}
							</div>
						</div>
						{index !== data.length - 1 && (
							<Dropdown.Divider className={'m-0 p-0'} />
						)}
					</React.Fragment>
				))}
			</div>
		)

	return (
		<div className={s.campaignDurationContainer}>
			{data.map((d, index) => (
				<div key={d.title}>
					<div className={'d-flex align-items-center'}>
						<span
							style={{
								marginRight: '20px',
								display: 'grid',
								placeItems: 'center',
							}}
						>
							{<d.icon />}
						</span>
						{d.showTitle && (
							<span style={{ whiteSpace: 'nowrap' }}>
								{d.title}
							</span>
						)}
					</div>

					<span
						style={{
							fontSize: '20px',
							marginLeft: '38px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}
					>
						{d.text}
					</span>
				</div>
			))}
		</div>
	)
}

export default CampaignDuration
