import React, { useEffect, useMemo, useState } from 'react'
import useCampaignData from 'src/stores/campaignData'
import {
	PlacementsType,
	PlacementsWithSelectType,
	RelatedKeyType,
} from '../../../CreateReportPage'
import { authFetch } from 'src/utils/authFetch'
import { ContentFormats, MainContent, Placements } from './components'

export type AdFormatNameType =
	| 'indoor'
	| 'outdoor'
	| 'internet'
	| 'tv'
	| 'publicTransport'

const createUrlForPlacementsFetch = (ids: string[]) => {
	const url = `/core/api/v1/adv_companies/used_placements/?${ids
		.map((id) => `ids=${id}`)
		.join('&')}`

	return url
}

type CreateReportStepThree = {
	contentFormats: Record<'video' | 'banner', boolean>
	setContentFormats: (formats: Record<'video' | 'banner', boolean>) => void
	outdoorPlacements: Array<PlacementsWithSelectType<string>>
	indoorPlacements: Array<PlacementsWithSelectType<string>>
	setIndoorPlacements: (
		placements: Array<PlacementsWithSelectType<string>>
	) => void
	setOutdoorPlacements: (
		placements: Array<PlacementsWithSelectType<string>>
	) => void
	campaignIds: Array<string>
	setRelatedKeysChange: (relatedKeys: Record<RelatedKeyType, boolean>) => void
	relatedKeys: Record<RelatedKeyType, boolean>
}

export function CreateReportStepThree({
	campaignIds,
	indoorPlacements,
	outdoorPlacements,
	setIndoorPlacements,
	setOutdoorPlacements,
	setRelatedKeysChange,
	relatedKeys,
	contentFormats,
	setContentFormats,
}: CreateReportStepThree) {
	const [campaign, campaignInterface] = useCampaignData()

	const [internetIsSelected, setInternetIsSelected] = useState(false)
	const [tvIsSelected, setTvIsSelected] = useState(false)
	const [publicTransortIsSelected, setPublicTransortIsSelected] =
		useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const campaignList = campaignInterface.list().results as Array<{
		id: string
		name: string
		district_adv_is_selected: boolean
		outdoor_adv_is_selected: boolean
		indoor_adv_is_selected: boolean
		extra_data: {
			tv_is_selected: boolean
			inet_is_selected: boolean
			transport_is_selected: boolean
		}
	}>

	const fetchOutdoors = async (isSelected: boolean) => {
		if (!isSelected) {
			setOutdoorPlacements([])
			return
		}
		setIsLoading(true)
		const selectedCampains = campaignList.filter(
			(campaign) =>
				campaignIds.some((id) => id === campaign.id) &&
				campaign.outdoor_adv_is_selected
		)

		const selectedCampaignsIds = selectedCampains.map(
			(campaign) => campaign.id
		)

		const url = createUrlForPlacementsFetch(selectedCampaignsIds)

		const result = await authFetch<PlacementsType<string>[]>({
			url: url,
			method: 'GET',
		})

		const placements = result.map((placement) => {
			return { ...placement, isSelected: true }
		})
		setOutdoorPlacements(placements)
		setIsLoading(false)
	}

	const fetchIndoors = async (isSelected: boolean) => {
		if (!isSelected) {
			setIndoorPlacements([])
			return
		}

		setIsLoading(true)
		const selectedCampains = campaignList.filter(
			(campaign) =>
				campaignIds.some((id) => id === campaign.id) &&
				campaign.indoor_adv_is_selected
		)

		const selectedCampaignsIds = selectedCampains.map(
			(campaign) => campaign.id
		)

		const url = createUrlForPlacementsFetch(selectedCampaignsIds)

		const result = await authFetch<PlacementsType<string>[]>({
			url: url,
			method: 'GET',
		})

		const placements = result.map((placement) => {
			return { ...placement, isSelected: true }
		})
		setIndoorPlacements(placements)
		setIsLoading(false)
	}

	const handleIndoorPlacementsChange = (placementId: string) => {
		setIndoorPlacements(
			indoorPlacements.map((placement) =>
				placement.id === placementId
					? { ...placement, isSelected: !placement.isSelected }
					: placement
			)
		)
	}

	const handleOutdoorPlacementsChange = (placementId: string) => {
		setOutdoorPlacements(
			outdoorPlacements.map((placement) =>
				placement.id === placementId
					? { ...placement, isSelected: !placement.isSelected }
					: placement
			)
		)
	}
	const handleRelatedKeysChange = (relatedKey: string) => {
		const currentValue = relatedKeys[relatedKey as RelatedKeyType]

		setRelatedKeysChange({
			...relatedKeys,
			[relatedKey]: !currentValue,
		})
	}
	const handlerContentFormatChange = (format: 'video' | 'banner') => {
		const currentValue = contentFormats[format]
		setContentFormats({ ...contentFormats, [format]: !currentValue })
	}

	const handleAdFormatChange = (
		name: AdFormatNameType,
		isSelected: boolean
	) => {
		if (name === 'indoor') fetchIndoors(isSelected)
		if (name === 'outdoor') fetchOutdoors(isSelected)
		if (name === 'internet') {
			setInternetIsSelected(isSelected)
			if (isSelected) {
				setRelatedKeysChange({
					...relatedKeys,
					yandexinternetadvertising: true,
					vkinternetadvertising: true,
				})
			} else {
				setRelatedKeysChange({
					...relatedKeys,
					yandexinternetadvertising: false,
					vkinternetadvertising: false,
				})
			}
		}
		if (name === 'tv') {
			setTvIsSelected(isSelected)
			if (isSelected) {
				setRelatedKeysChange({
					...relatedKeys,
					adstreamadversting: true,
					teletargetadversting: true,
				})
			} else {
				setRelatedKeysChange({
					...relatedKeys,
					adstreamadversting: false,
					teletargetadversting: false,
				})
			}
		}
		if (name === 'publicTransport') {
			setPublicTransortIsSelected(isSelected)

			if (isSelected) {
				setRelatedKeysChange({
					...relatedKeys,
					public_transport: true,
				})
			} else {
				setRelatedKeysChange({
					...relatedKeys,
					public_transport: false,
				})
			}
		}
	}

	const disabledAdFormats = useMemo(() => {
		const disabledFormats = {
			indoor: true,
			outdoor: true,
			internet: true,
			tv: true,
			publicTransport: true,
		}

		const selectedCampaigns = campaignList.filter((campaign) =>
			campaignIds.some((id) => id === campaign.id)
		)

		selectedCampaigns.forEach((campaign) => {
			if (campaign.indoor_adv_is_selected) {
				disabledFormats.indoor = false
			}
			if (
				campaign.outdoor_adv_is_selected ||
				campaign.district_adv_is_selected
			) {
				disabledFormats.outdoor = false
			}
			if (campaign.extra_data.inet_is_selected) {
				disabledFormats.internet = false
			}
			if (campaign.extra_data.tv_is_selected) {
				disabledFormats.tv = false
			}
			if (campaign.extra_data.transport_is_selected) {
				disabledAdFormats.publicTransport = false
			}
		})

		return disabledFormats
	}, [campaignList, campaignIds])

	useEffect(() => {
		return () => {
			setIndoorPlacements([])
			setOutdoorPlacements([])
		}
	}, [])
	return (
		<div>
			<MainContent
				disabledAdFormats={disabledAdFormats}
				handleAdFormatChange={handleAdFormatChange}
			/>

			<Placements
				handleIndoorPlacementsChange={handleIndoorPlacementsChange}
				handleOutdoorPlacementsChange={handleOutdoorPlacementsChange}
				handleRelatedKeysChange={handleRelatedKeysChange}
				publicTransportIsSelected={publicTransortIsSelected}
				indoorPlacements={indoorPlacements}
				internetIsSelected={internetIsSelected}
				isLoading={isLoading}
				outdoorPlacements={outdoorPlacements}
				relatedKeys={relatedKeys}
				tvIsSelected={tvIsSelected}
			/>

			<ContentFormats
				contentFormats={contentFormats}
				handlerContentFormatChange={handlerContentFormatChange}
			/>
		</div>
	)
}
