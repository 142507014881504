import BookingOperationsButtons, {
	IOperationsButtons,
} from '../content/BookingOperationsButtons'
import { useMemo } from 'react'

type Input = {
	bookingInModeration: boolean
}
type Output = {
	buttons: IOperationsButtons[]
}
const useBookingsOperations = ({ bookingInModeration }: Input): Output => {
	const Filtered = useMemo(() => {
		if (!bookingInModeration)
			return BookingOperationsButtons.filter(
				(button) => !button.showOnlyOnModeration
			)
		return BookingOperationsButtons
	}, [bookingInModeration])

	return { buttons: Filtered }
}

export default useBookingsOperations
