import React, { useCallback, useState } from 'react'
import { Card } from 'react-bootstrap'
import s from '../../garantid-card/garantid-media-format-card/garantidMediaFormatCard.module.scss'
import { DistrictBookingItemCardHeader } from './components/DistrictBookingItemCardHeader'
import {
	AdvItemState,
	DistrictBookingItem,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import { DistrictBookingItemStatus } from './components/DistrictBookingItemStatus'
import { AnimatePresence } from 'framer-motion'
import { DistrictBookingFormat } from './components/districtBooking-format/DistrictBookingFormat'
import ls from './districtBookingItemCard.module.scss'
import { CommonMediaModal } from '../../garantid-card/garantid-media-format-card/components/common-media/modals/CommonMediaModal'
import { useConfirm } from 'src/components/UtilityComponents/ConfirmModalProvider'
import useCampaignData from 'src/stores/campaignData'
import { DistrictBookingEdit } from './components/districtBooking-edit/DistrictBookingEdit'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'

export type DistrictBookingItemModals = 'edit'
const modalTitles: Record<DistrictBookingItemModals, string> = {
	edit: 'Исправить',
}

const removeAblesStates = [
	AdvItemState.Adjustment,
	AdvItemState.Inner_Moderation,
]
const editAbleState = [AdvItemState.Adjustment]

type Props = {
	districtBooking: DistrictBookingItem
}
export const DistrictBookingItemCard = ({ districtBooking }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { confirm } = useConfirm()
	const [editVinstant, setEditVinstant] = useState<{
		contentKey: string
		link: string
	} | null>(null)

	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)
	const [modalType, setModalType] =
		useState<DistrictBookingItemModals | null>(null)

	const handleRemoveDistrictBooking = useCallback(async () => {
		const result = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Вы уверены, что хотите удалить размещение в максимальном охвате из рекламной кампании?',
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (result) {
			await campaignInterface.updateDistrictBooking({
				districtId: districtBooking.id,
				state: AdvItemState.Canceled,
			})
			const campaign = await campaignInterface.refetchSelected()
			if (campaign) {
				await fetchTechnicalRequirements(campaign)
			}
		}
	}, [])

	const isRemoveAble = removeAblesStates.includes(districtBooking.state)
	const isEditable = editAbleState.includes(districtBooking.state)

	const handleModalClose = useCallback(() => {
		setModalType(null)
	}, [])
	return (
		<Card className={s.garantidMediaFormatCard}>
			<DistrictBookingItemCardHeader
				isEditButton={isEditable}
				isRemoveButton={isRemoveAble}
				onRemove={handleRemoveDistrictBooking}
				setModalType={setModalType}
				cityBudget={districtBooking.amount}
				cityName={districtBooking.city_name}
			/>
			<DistrictBookingItemStatus districtBooking={districtBooking} />

			<div className={ls.districtBookingFormatContainer}>
				<AnimatePresence>
					{districtBooking.advFormats.map((mediaFormat) => (
						<DistrictBookingFormat
							districtBookingId={districtBooking.id}
							key={mediaFormat.id}
							mediaFormat={mediaFormat}
						/>
					))}
				</AnimatePresence>
			</div>

			{editVinstant && (
				<EditVinstantModal
					newContentKey={editVinstant.contentKey}
					editVinstantLink={editVinstant.link}
					onClose={() => setEditVinstant(null)}
					open={!!editVinstant?.link}
				/>
			)}

			<CommonMediaModal
				onClose={handleModalClose}
				open={!!modalType}
				title={modalTitles[modalType || 'edit']}
			>
				{modalType === 'edit' && (
					<DistrictBookingEdit
						onClose={handleModalClose}
						onEditVinstant={setEditVinstant}
						districtBooking={districtBooking}
					/>
				)}
			</CommonMediaModal>
		</Card>
	)
}
