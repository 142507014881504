import React, { useEffect, useState } from 'react'
import ls from '../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { ReactComponent as Billboard } from '../../../../../../../../assets/images/newbillboard.svg'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { OutdoorAccordionBody } from './accorion-bodies/OutdoorAccordionBody'
import { AdvItemStatistic } from '../common/AdvItemStatistic'
import { authFetch } from 'src/utils/authFetch'
import { CommonAdvAccordionsProps } from '../CampaignDetailedInfo'
import { DistrictBookinAccordionBody } from './accorion-bodies/DistrictBookinAccordionBody'
import { getAdjustmentMessage } from 'src/components/_moderator/utils'
import { DistrictType } from 'src/components/_client/create-campaign/Step2-ADformats/outdoor-ad/types'

export const OutdoorDetailedAccordion = ({
	setDetailedItem,
	setAdvItemActon,
	onEditVinstant,
}: CommonAdvAccordionsProps) => {
	const [moder, moderInterface] = useModerationData()

	const disrictBookings = moder?.detailedCampaign?.district_booking
	const outdoorBookings = moder?.detailedCampaign?.booking?.filter(
		(booking) => booking.placement.door_type === 'outdoor'
	)
	const [districts, setDistricts] = useState<DistrictType[]>([])
	const fetchDistricts = async () => {
		const result = await authFetch<Array<DistrictType>>({
			method: 'GET',
			url: '/core/api/v1/campaign/districts',
		})
		if (result) {
			setDistricts(result)
		}
	}

	const getCityName = (cityId) => {
		return districts.find((el) => Number(cityId) === el.id)?.title
	}

	const totalPrice =
		outdoorBookings?.reduce(
			(acc, current) =>
				acc + parseFloat(current.placement_showing_count.price),
			0
		) +
		disrictBookings?.reduce(
			(acc, current) => acc + parseFloat(current.amount),
			0
		)

	const showsTotal = outdoorBookings.reduce(
		(acc, current) =>
			acc + current.placement_showing_count.showing_count_per_day,
		0
	)

	useEffect(() => {
		fetchDistricts()
	}, [])
	return (
		<Accordion className={ls.accordion} defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header as={'div'} className={ls.accordionHeader}>
					<Billboard style={{ fill: '#212529' }} />{' '}
					<h6>Наружная реклама</h6>
				</Accordion.Header>

				{moder.detailedCampaign.outdoor_adv_is_selected &&
					!!outdoorBookings.length &&
					outdoorBookings.map((booking) => (
						<OutdoorAccordionBody
							onEditVinstant={onEditVinstant}
							adjusmentMessage={getAdjustmentMessage(
								booking.state,
								booking.adjustment_msg,
								booking.reject_msg
							)}
							setAdvItemActon={setAdvItemActon}
							setDetailedItem={setDetailedItem}
							key={booking.id}
							booking={booking}
						/>
					))}
				{moder.detailedCampaign.district_adv_is_selected &&
					!!disrictBookings.length &&
					disrictBookings.map((disrictBooking) => (
						<DistrictBookinAccordionBody
							onEditVinstant={onEditVinstant}
							adjusmentMessage={getAdjustmentMessage(
								disrictBooking.state,
								disrictBooking.adjustment_msg,
								disrictBooking.reject_msg
							)}
							setAdvItemActon={setAdvItemActon}
							setDetailedItem={setDetailedItem}
							cityName={getCityName(disrictBooking.city_id)}
							key={disrictBooking.id}
							districtbooking={disrictBooking}
						/>
					))}
				<Accordion.Body style={{ padding: '0 8px 0 16px' }}>
					<AdvItemStatistic
						style={{ marginTop: 0 }}
						price={totalPrice}
						shows={showsTotal}
						total={outdoorBookings.length + disrictBookings?.length}
					/>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}
