import React, { useCallback, useEffect, useState } from 'react'
import {
	AdvFormatBookingItem,
	GarantidItem,
	MediaRequirements,
	PlacementViewModel,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import s from './garantidItem.module.scss'
import { GarandidCampaignItemHeader } from './GarandidCampaignItemHeader'
import { GarantidCampaignItemStateWithCreative } from './GarantidCampaignItemStateWithCreative'
import { motion } from 'framer-motion'
import useNotificationData from 'src/stores/notificationsData'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { GarantidCampaignItemModal } from './modals/GarantidCampaignItemModal'
import { MediaFormatRequirements } from '../common/MediaFormatRequirements'
import { InfoAboutPlaceWrapper } from '../common/InfoAboutPlaceWrapper'
import { BookingEditWrapper } from '../common/BookingEditWrapper'
import { useConfirm } from 'src/components/UtilityComponents/ConfirmModalProvider'
import useCampaignData from 'src/stores/campaignData'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { CommonMediaModal } from '../common-media/modals/CommonMediaModal'

export type GarantidItemModal = 'edit' | 'placementInfo' | 'requirements'

const modalTitles: Record<GarantidItemModal, string> = {
	edit: 'Исправление рекламного места',
	placementInfo: 'Подробнее о месте',
	requirements: 'Требования к загрузке',
}

type Props = {
	bookingItem: AdvFormatBookingItem<GarantidItem, PlacementViewModel>
	mediaRequirements: MediaRequirements
}
export const GarantidCampaignItem = ({
	bookingItem,
	mediaRequirements,
}: Props) => {
	const [_, notificationsInterface] = useNotificationData()
	const [campaign, campaignInterface] = useCampaignData()
	const { confirm } = useConfirm()

	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)

	const [modalType, setModalType] = useState<GarantidItemModal | null>(null)
	const [isEditVinstantOpen, setIsEditVinstantOpen] = React.useState(false)

	const screenResolution = `${mediaRequirements.max_width} x ${mediaRequirements.max_height} px`

	const bookingVinstantEditKey = bookingItem.item.id.toString()

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(bookingVinstantEditKey) as
			| NewContentCreatedStatusType
			| undefined

	const bookingVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: bookingVinstantEditKey,
		})
	)

	const handleEditVinstantModalClose = useCallback(() => {
		setIsEditVinstantOpen(false)
	}, [])
	const handleEditVinstantModalOpen = useCallback(() => {
		setIsEditVinstantOpen(true)
	}, [])
	const handleGarantidModalClose = useCallback(() => {
		setModalType(null)
	}, [])

	const refetchAfterVinstantEnd = async () => {
		await fetchVinstantCreative(campaign.id)
		await fetchTechnicalRequirements(campaign)
		notificationsInterface.setNewContendCreated(
			bookingVinstantEditKey,
			'pending'
		)
	}

	const handeRemoveBooking = async () => {
		const result = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Вы уверены, что хотите удалить рекламное место из рекламной кампании?',
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (result) {
			await campaignInterface.cancelBooking(bookingItem.item.id)
			await refetchAfterVinstantEnd()
		}
	}

	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			refetchAfterVinstantEnd()
		}
	}, [editCreativeVinstantStatus])
	return (
		<motion.div
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			className={s.garandidCampaignItem}
		>
			<GarandidCampaignItemHeader
				onRemove={handeRemoveBooking}
				setModal={setModalType}
				state={bookingItem.item.state}
				placement={bookingItem.placement}
				budget={bookingItem.item.booking_budget}
			/>
			<GarantidCampaignItemStateWithCreative
				setModal={setModalType}
				isVinstantLoading={editCreativeVinstantStatus === 'start'}
				screenResolution={screenResolution}
				item={bookingItem.item}
			/>
			<EditVinstantModal
				newContentKey={bookingVinstantEditKey}
				editVinstantLink={bookingVinstantLink}
				onClose={handleEditVinstantModalClose}
				open={isEditVinstantOpen}
			/>

			<CommonMediaModal
				onClose={handleGarantidModalClose}
				open={!!modalType}
				title={modalTitles[modalType || 'requirements']}
			>
				{modalType === 'requirements' && (
					<MediaFormatRequirements
						mediaRequierments={mediaRequirements}
					/>
				)}
				{modalType === 'placementInfo' && (
					<InfoAboutPlaceWrapper bookingId={bookingItem.item.id} />
				)}
				{modalType === 'edit' && (
					<BookingEditWrapper
						onClose={handleGarantidModalClose}
						bookingId={bookingItem.item.id}
						onVinstantClick={handleEditVinstantModalOpen}
					/>
				)}
			</CommonMediaModal>
		</motion.div>
	)
}
