import {
	AcceptBookingIcon,
	DownloadBookingIcon,
	EditBookingIcon,
	RejectBookingIcon,
} from './icons/ButtonsOperationsIcons'
import { ButtonVariant } from 'react-bootstrap/types'

export interface IOperationsButtons {
	color: string
	text: string
	actionType: 'accept' | 'edit' | 'reject' | 'download'
	showOnlyOnModeration: boolean
	icon: (props: Record<any, any>) => JSX.Element
	variant: ButtonVariant
}

const BookingOperationsButtons: IOperationsButtons[] = [
	{
		text: 'Подтвердить',
		color: '#007BFF',
		actionType: 'accept',
		showOnlyOnModeration: true,
		icon: AcceptBookingIcon,
		variant: 'primary',
	},
	{
		text: 'Исправить',
		color: '#007BFF',
		actionType: 'edit',
		showOnlyOnModeration: true,
		icon: EditBookingIcon,
		variant: 'primary',
	},

	{
		text: 'Выгрузить в Excel',
		color: '#007BFF',
		actionType: 'download',
		showOnlyOnModeration: false,
		icon: DownloadBookingIcon,
		variant: 'secondary',
	},
	{
		text: 'Отклонить',
		color: '#DC3545',
		actionType: 'reject',
		showOnlyOnModeration: true,
		icon: RejectBookingIcon,
		variant: 'danger',
	},
]

export default BookingOperationsButtons
