import React from 'react'
import { ReportCheckBox } from '../../../common'
import { PlacementsWithSelectType } from '../../../../CreateReportPage'
import s from '../createReportThree.module.scss'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'

type CreateReportPlacementsListProps<T> = {
	placements: PlacementsWithSelectType<T>[]
	title: string
	onChange: (placementId: string) => void
}
export const CreateReportPlacementsList = <T extends string>({
	placements,
	onChange,
	title,
}: CreateReportPlacementsListProps<T>) => {
	const { isMobile } = useResponsive()

	return (
		<div
			className={cn(s.reportPlacement, {
				[s.reportPlacement_mobile]: isMobile,
			})}
		>
			<p>{title}</p>

			{placements.map((placement) => (
				<div key={placement?.id}>
					<ReportCheckBox
						checked={placement.isSelected}
						onChange={() => onChange(placement.id)}
						text={placement.placement_type.name}
					>
						<div>{placement.name}</div>
					</ReportCheckBox>
				</div>
			))}
		</div>
	)
}
