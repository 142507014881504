import { Modal, Spinner } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import { CreativeTemplateKeysType, CreativeTemplateType } from '../../constants'
import React, { useContext, useEffect, useState } from 'react'
import { vinstantIntegration } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { TemplateInfo } from './template-info'
import {
	DistrictBookingExternalType,
	PlacementType,
} from 'src/stores/ADMarketTypes.types'
import { TemplateImagesList } from './template-images-list'
import { AnimatePresence } from 'framer-motion'
import { TemplateIframe } from './temlate-iframe'
import s from './templates-modal.module.scss'
import cn from 'classnames'
import useNotificationData, { BookingId } from 'src/stores/notificationsData'
import useCampaignData from 'src/stores/campaignData'
import { NewContentCreatedKeysType } from 'src/stores/types/newContendCreatedType'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

type TemplatesModalPropsType = {
	externalType?: DistrictBookingExternalType
	modalOpen: boolean
	closeModal: () => void
	screenResolution: CreativeTemplateKeysType
	instanceId: string
	clsName: ClsNameType
	campaingId?: string
	placement?: Omit<PlacementType, 'placement_showing_counts'>
	newContentKey: NewContentCreatedKeysType | BookingId
	setLoadingInProgress?: (inProgress: boolean) => void
	isCollapsedDefault?: boolean
	getOrderLayoutModal?: () => void
}

type ClsNameType =
	| 'booking'
	| 'vkinternetadvertising'
	| 'yandexinternetadvertising'
	| 'teletargetadversting'
	| 'adstreamadversting'
	| 'districtbooking'
	| 'publictransportadvertising'

/**
 * Модальное окно с формой выбора шаблона рекламной кампаниип
 * @component
 * @param {CreativeTemplateKeysType} screenResolution - ключ для доступа к свойству статического объекта
 * @param {Object} placement - объект в котором хранится информация о текучем месте
 * @param {ClsNameType} clsName - тип формата рекламы
 * @param instanceId - идентификатор выбранного места или формата рекламы
 * @param campaingId - id рк
 */
export const TemplatesModal = ({
	closeModal,
	modalOpen,
	screenResolution,
	placement,
	clsName,
	instanceId,
	campaingId: decprecatedCampaingId,
	newContentKey,
	isCollapsedDefault,
	externalType,
	getOrderLayoutModal,
}: TemplatesModalPropsType) => {
	const { isMobile, isDesktop } = useResponsive()

	const [campaing, campaignInterface] = useCampaignData()
	const campaingId = campaing.id
	const [, notificationsInterface] = useNotificationData()

	const [iframeURL, setIframeURL] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState(false)
	const [templateId, setTemplateId] = useState<number | undefined>(undefined)
	const [isCollapsed, setIsCollapsed] = useState(!!isCollapsedDefault)

	const { addToast } = useContext(ToastsContext)

	const newContentStatus =
		notificationsInterface.getNewContentCreated(newContentKey)

	const onChooseTemplate = async (template: CreativeTemplateType) => {
		setIsLoading(true)
		setTemplateId(template.templateId)

		const requestBody = {
			cls_name: clsName,
			instance_id: instanceId,
			template_id: template.templateId,
		} as any

		if (template.templateId === 0) delete requestBody['template_id']
		if (externalType) requestBody.external_type = externalType

		try {
			const result = await authFetch<{ redirect_url: string }>({
				url: vinstantIntegration.params(campaingId),
				method: 'POST',
				body: requestBody,
			})
			setIframeURL(result?.redirect_url)
			setIsCollapsed(true)
		} catch {
			setIframeURL(undefined)
		} finally {
			setIsLoading(false)
			setTemplateId(undefined)
		}
	}

	useEffect(() => {
		if (newContentStatus === 'start') {
			addToast({
				text: `Начата загрузка контента`,
				type: 'info',
			})
			closeModal()
		}
	}, [newContentStatus])

	useEffect(() => {
		if (!iframeURL && isCollapsed) {
			setIsCollapsed(false)
		}
	}, [iframeURL])
	return (
		<Modal
			onHide={closeModal}
			centered
			size={'sm'}
			animation={true}
			show={modalOpen}
			dialogClassName="modal-fullscreen"
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Cоздание материала{' '}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: '0' }} className={cn(s.modalBody)}>
				<div
					className={cn(s.templatesModalBodyItem, {
						[s.templatesModalBodyItem_mobile]: isMobile,
					})}
				>
					{!isCollapsed && placement && (
						<TemplateInfo
							isCollapsed={isCollapsed}
							placement={placement}
						/>
					)}

					<AnimatePresence>
						{iframeURL ? (
							<TemplateIframe url={iframeURL} />
						) : (
							<TemplateImagesList
								isAvailableVinstant={
									campaing.is_available_vinstant
								}
								getOrderLayoutModal={getOrderLayoutModal}
								templateId={templateId}
								isLoading={isLoading}
								screenResolution={screenResolution}
								onChooseTemplate={onChooseTemplate}
							/>
						)}
					</AnimatePresence>
				</div>
			</Modal.Body>

			{isMobile && (
				<Modal.Footer>
					<button
						onClick={() => {
							if (iframeURL) {
								setIframeURL(undefined)
							} else {
								closeModal()
							}
						}}
						type="button"
						className="btn btn-secondary"
					>
						Отменить
					</button>
					<button
						disabled={!!iframeURL}
						onClick={() =>
							onChooseTemplate({ templateId: 0 } as any)
						}
						type="button"
						className="btn btn-light"
						style={{ width: '50%' }}
					>
						{isLoading ? (
							<div>
								<Spinner
									size="sm"
									animation={'border'}
									variant={'primary'}
								/>
							</div>
						) : (
							'Создать материал'
						)}
					</button>
				</Modal.Footer>
			)}
		</Modal>
	)
}
