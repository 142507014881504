import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import AuthPage from './components/auth/AuthPage'
import ROUTES from './constants/Routes'
import React, { useEffect } from 'react'
import ToastsContextProvider from './components/UtilityComponents/ToastsContextProvider'
import ActivityController from './components/UtilityComponents/ActivityController'
import InviteProviderPage from './components/_provider/provider_invite/InviteProviderPage'
import PrivateUserRoute from './components/UtilityComponents/PrivateUserRoute'
import ProviderPage from './components/_provider/provider_page/ProviderPage'
import PrivateProviderRoute from './components/UtilityComponents/PrivateProviderRoute'
import CreateCampaignPage from './components/_client/create-campaign/CreateCampaignPage'
import { ConfirmModalProvider } from './components/UtilityComponents/ConfirmModalProvider'
import NotificationCheckRoute from './components/UtilityComponents/NotificationCheckRoute'
import ProfileCheckRoute from './components/UtilityComponents/ProfileCheckRoute'
import SettingsCheckRoute from './components/UtilityComponents/SettingsCheckRoute'
import ErrorBoundary from './components/UtilityComponents/ErrorBoundary'
import * as Sentry from '@sentry/react'
import CampaignsList from './components/_client/campaigns-list/CampaignsList'
import CampaignInfoController from './components/_client/campaign-info/CampaignInfoController'
import DeveloperRouteCheck from './components/UtilityComponents/DeveloperRouteCheck'
import UserOperationsHistoryPage from './components/_client/user-operations/UserOperationsHistoryPage'
import ReusableModalsProvider from './components/UtilityComponents/ReusableModals/ReusableModalsProvider'
import CreateReportPage from './components/_client/create-report/CreateReportPage'

import { PrivateModeratorRoute } from './components/UtilityComponents'
import { ModeratorPage } from './components/_moderator'
import { UserHelpPage, UserHelpPageComponent } from './components/_client/help'

function AppRouter() {
	useEffect(() => {
		const setVh = () => {
			// Вычисляем 1vh как 1% от высоты видимого окна
			const vh = window.innerHeight * 0.01
			// Устанавливаем CSS переменную --vh
			document.documentElement.style.setProperty('--vh', `${vh}px`)
		}

		// Устанавливаем значение при загрузке страницы
		setVh()

		// Обновляем значение при изменении размера окна
		window.addEventListener('resize', setVh)

		// Удаляем обработчик события при размонтировании компонента
		return () => window.removeEventListener('resize', setVh)
	}, [])

	return (
		<>
			<ActivityController />
			<ToastsContextProvider>
				<ConfirmModalProvider>
					<ReusableModalsProvider>
						<BrowserRouter>
							<Switch>
								{/*Роуты без авторизации*/}
								<Route path={ROUTES.ROUTE_AUTH}>
									<AuthPage />
								</Route>
								<Route path={ROUTES.ROUTE_INVITE_PROVIDER}>
									<InviteProviderPage />
								</Route>
								<Route path={ROUTES.ROUTE_HELP_PUBLIC}>
									<UserHelpPageComponent publicPage={true} />
								</Route>

								{/*Роут для тестирования новых компонентов, только для разработчиков*/}
								<Route path={'/dev'}>
									<DeveloperRouteCheck />
								</Route>

								{/*Общие роуты*/}
								<NotificationCheckRoute
									path={ROUTES.ROUTE_NOTIFICATIONS}
								/>
								<ProfileCheckRoute
									path={ROUTES.ROUTE_PROFILE}
								/>
								<SettingsCheckRoute
									path={ROUTES.ROUTE_SETTINGS}
								/>

								{/*Роуты клиента*/}
								<PrivateUserRoute
									path={ROUTES.ROUTE_CAMPAIGNS}
									exact
								>
									<CampaignsList />
								</PrivateUserRoute>

								<PrivateUserRoute
									path={ROUTES.CREATE_REPORT}
									exact
								>
									<CreateReportPage />
								</PrivateUserRoute>

								<PrivateUserRoute
									path={ROUTES.ROUTE_CAMPAIGN_INFO}
								>
									<CampaignInfoController />
								</PrivateUserRoute>
								<PrivateUserRoute path={ROUTES.CREATE_CAMPAIGN}>
									<CreateCampaignPage />
								</PrivateUserRoute>
								<PrivateUserRoute
									path={ROUTES.ROUTE_OPERATIONS}
								>
									<UserOperationsHistoryPage />
								</PrivateUserRoute>

								<PrivateUserRoute path={ROUTES.ROUTE_HELP}>
									<UserHelpPage publicPage={false} />
								</PrivateUserRoute>
								{/*Роуты провайдера*/}
								<PrivateProviderRoute
									path={ROUTES.ROUTE_PROVIDER}
								>
									<ProviderPage />
								</PrivateProviderRoute>

								{/*Роуты Модератора*/}
								<PrivateModeratorRoute
									path={ROUTES.ROUTE_MODERATOR}
								>
									<ModeratorPage />
								</PrivateModeratorRoute>

								{/*Общий редирект если роут не найден*/}
								<Route path={'/'}>
									<Redirect to={ROUTES.ROUTE_AUTH} />
								</Route>
							</Switch>
						</BrowserRouter>
					</ReusableModalsProvider>
				</ConfirmModalProvider>
			</ToastsContextProvider>
		</>
	)
}

export default Sentry.withErrorBoundary(AppRouter, {
	fallback: ErrorBoundary,
})
