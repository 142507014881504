import React, { FC, useMemo, useState } from 'react'
import {
	InternetAdType,
	InternetState,
} from '../../../../stores/ADMarketTypes.types'
import { Button } from 'react-bootstrap'
import EditInternetModal from './EditInternetModal'
import useResponsive from '../../../../utils/useResponsive'
import { EditVinstantModal } from '../common/edit-vinstant-modal/EditVinstantModal'
import useNotificationData from 'src/stores/notificationsData'
import { useCampaignInfoStore } from '../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { StateWithMessage } from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/StateWithMessage'
import {
	defaultMessageData,
	MessageProperties,
	MessagesData,
} from '../constants/messages-data'
import { AdvItemState } from 'src/stores/technical-requirements-store'

interface IInternetStateView {
	internet: InternetAdType
	name: 'yandex' | 'vk'
}

const InternetStateView: FC<IInternetStateView> = ({ internet, name }) => {
	const { state, adjustment_msg, reject_msg } = internet
	let data: MessageProperties =
		MessagesData[internet.state] || defaultMessageData

	if (state === InternetState.Adjustment) {
		data.message = adjustment_msg
	}
	if (state === InternetState.Reject) {
		data.message = reject_msg
	}
	const [showEditModal, setShowEditModal] = useState(false)
	const { isMobile } = useResponsive()
	const fontSize = useMemo<string>(
		() => (isMobile ? '14px' : '16px'),
		[isMobile]
	)

	const [_, notificationsInterface] = useNotificationData()

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(name) as string | undefined

	const bookingVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: name === 'vk' ? 'vk_id' : 'yandex_id',
			itemId: internet.id.toString(),
		})
	)
	const [isEditVinstantOpen, setIsEditVinstantOpen] = useState(false)

	const handleEditVinstantModalClose = () => {
		setIsEditVinstantOpen(false)
		setShowEditModal(true)
	}

	if (!data) return <></>

	return (
		<div
			className={'d-flex flex-column'}
			style={{
				color: data.color,
				fontSize,
				marginBottom: isMobile ? '16px' : '24px',
			}}
		>
			{internet.state !== InternetState.Completed && (
				<StateWithMessage stateData={data} styles={{ width: '100%' }} />
			)}

			{state === InternetState.Adjustment && (
				<>
					<Button
						variant={'primary'}
						className={'mt-3'}
						style={{ width: isMobile ? '100%' : 'fit-content' }}
						onClick={() => {
							setShowEditModal(true)
						}}
					>
						Исправить
					</Button>
					<EditInternetModal
						editVinstantStatus={editCreativeVinstantStatus}
						onEditVinstantClick={() => setIsEditVinstantOpen(true)}
						show={showEditModal}
						handleClose={() => {
							setShowEditModal(false)
						}}
						internetName={name}
						internet={internet}
					/>
					<EditVinstantModal
						newContentKey={name}
						editVinstantLink={bookingVinstantLink}
						onClose={handleEditVinstantModalClose}
						open={isEditVinstantOpen}
					/>
				</>
			)}
		</div>
	)
}

export default InternetStateView
