import React, { FC, useMemo } from 'react'
import s from './list.module.scss'
import {
	BookingDetails,
	BookingListItem,
} from '../../../../../stores/ProviderTypes.types'
import { Form } from 'react-bootstrap'
import { BookingBadge } from '../components/BookingBadge'
import BookingIcon from '../components/BookingIcon'
import DetailItem from '../../../../../utils/DetailItem'
import moment from 'moment'
import { useBookingsContext } from '../../context/BookingsProvider'
import useResponsive from 'src/utils/useResponsive'
import { ConditionArrowLeftRight } from 'src/components/UtilityComponents'

interface IBookingItem {
	booking: BookingListItem
	groupSelected: number[]
	onCheckBoxClick: (id: number) => void
	setSelectedBookingId: React.Dispatch<
		React.SetStateAction<number | undefined>
	>
}

const PlacementInfoSidebar: FC<IBookingItem> = ({
	booking,
	groupSelected,
	onCheckBoxClick,
	setSelectedBookingId,
}) => {
	const { selectedBooking, setSelectedBooking } = useBookingsContext()
	const isSelectedInGroup = groupSelected.includes(booking.id)
	const durationString = useMemo<string>(() => {
		if (
			booking.advertising_company.start_date &&
			booking.advertising_company.end_date
		) {
			const s = moment(booking.advertising_company.start_date)
			const e = moment(booking.advertising_company.end_date)
			return `c ${s.format('DD.MM.YY')} по ${e.format('DD.MM.YY')}`
		} else {
			return ''
		}
	}, [booking])

	const { isMobile, isDesktop } = useResponsive()
	const backgroundColor =
		selectedBooking === booking.id
			? '#007bff1a'
			: isSelectedInGroup
			? '#F5F5F5'
			: ''

	return (
		<li className={s.listItem} style={{ backgroundColor: backgroundColor }}>
			{!isMobile && (
				<div
					className={s.itemCheckBox}
					onClick={() => {
						onCheckBoxClick(booking.id)
					}}
				>
					<Form.Check
						checked={isSelectedInGroup}
						onChange={(e) => {}}
					/>
				</div>
			)}
			<div
				className={s.listInfo}
				onClick={() => setSelectedBooking(booking.id)}
			>
				<section>
					<BookingIcon
						state={booking.state}
						image={booking.placement.placement_type.image}
					/>
					<div style={{ flex: '1 1 0' }}>
						<h6 className={'m-0'}>
							{booking.placement.placement_type.name}
						</h6>
						<span className={'text-secondary'}>
							{booking.placement.name}
						</span>
					</div>
					{!isMobile && <BookingBadge state={booking.state} />}
				</section>
				<div className={'d-flex flex-column gap-1 mt-2'}>
					<DetailItem
						left={'ID:'}
						right={booking.id}
						leftFixWidth={'80px'}
						fontSize={'12px'}
						bottomDivider={false}
						padding={0}
						styles={{ display: 'flex', gap: '1rem' }}
					/>
					<DetailItem
						left={'Название:'}
						right={booking.advertising_company.name}
						leftFixWidth={'80px'}
						fontSize={'12px'}
						bottomDivider={false}
						padding={0}
						styles={{ display: 'flex', gap: '1rem' }}
					/>
					<DetailItem
						left={'Заказчик:'}
						right={booking.advertising_company.company.name}
						leftFixWidth={'80px'}
						fontSize={'12px'}
						bottomDivider={false}
						padding={0}
						styles={{ display: 'flex', gap: '1rem' }}
					/>
					<DetailItem
						left={'Срок:'}
						right={durationString}
						leftFixWidth={'80px'}
						fontSize={'12px'}
						bottomDivider={false}
						padding={0}
						styles={{ display: 'flex', gap: '1rem' }}
					/>
				</div>

				{!isDesktop && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginTop: '8px',
						}}
						onClick={() => setSelectedBookingId(booking.id)}
					>
						<BookingBadge state={booking.state} />
						<ConditionArrowLeftRight
							styles={{ width: '16px', fill: '#6C757D' }}
							open={false}
						/>
					</div>
				)}
			</div>
		</li>
	)
}

export default PlacementInfoSidebar
