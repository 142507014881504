import { BookingState } from 'src/stores/ADMarketTypes.types'

export type MessageProperties = {
	icon: string
	key: BookingState
	title: string
	color: string
	progress: number
	message: string
}

export const defaultMessageData: MessageProperties = {
	color: 'red',
	icon: 'icon',
	title: 'default message',
	key: BookingState.Draft,
	message: 'something went wrong',
	progress: 0,
}

export const MessagesData: { [key: string]: MessageProperties } = {
	[BookingState.Draft]: {
		key: BookingState.Draft,
		icon: 'bi bi-file-earmark',
		title: 'Черновик',
		color: '#6C757D',
		progress: 0,
		message: '',
	},
	[BookingState.Moderation]: {
		key: BookingState.Moderation,
		icon: 'bi bi-hourglass-split',
		title: 'На модерации',
		color: '#007BFF',
		progress: 33,
		message:
			'Проверяем ваши изображения и наличие свободных мест на эти даты. Как только все проверим, отправим уведомление на почту.',
	},
	[BookingState.Inner_Moderation]: {
		key: BookingState.Inner_Moderation,
		icon: 'bi bi-hourglass-split',
		title: 'Внутренняя модерация',
		color: '#007BFF',
		progress: 33,
		message:
			'Проверяем ваши изображения и наличие свободных мест на эти даты. Как только все проверим, отправим уведомление на почту.',
	},
	[BookingState.Adjustment]: {
		key: BookingState.Adjustment,
		icon: 'bi bi-pencil',
		title: 'На исправлении',
		color: '#007BFF',
		progress: 50,
		message: 'Provider Message',
	},
	[BookingState.Confirm]: {
		key: BookingState.Confirm,
		icon: 'bi bi-check-circle',
		title: 'Подтверждено',
		color: '#28A745',
		progress: 100,
		message: 'Ваше изображение и бронь подтверждены.',
	},
	[BookingState.Reject]: {
		key: BookingState.Reject,
		icon: 'bi bi-x-circle',
		title: 'Отклонено',
		color: '#DC3545',
		progress: 100,
		message:
			'Бронь отклонена. Возможно рекламное место неисправно или занято на эту дату.',
	},
	[BookingState.Canceled]: {
		key: BookingState.Canceled,
		icon: 'bi bi-dash-circle',
		title: 'Отменено',
		color: '#DC3545',
		progress: 100,
		message: 'Вы отменили бронь.',
	},
	[BookingState.Inner_Confirm]: {
		key: BookingState.Inner_Confirm,
		icon: 'bi bi-hourglass-split',
		title: 'На модерации',
		color: '#007BFF',
		progress: 50,
		message:
			'Проверяем ваши изображения и наличие свободных мест на эти даты. Как только все проверим, отправим уведомление на почту.',
	},
}
