import React from 'react'
import cn from 'classnames'
import baseStyle from '../../createReportSteps.module.scss'
import { CreateReportBody } from '../../../common'
import { CreateReportPlacementHeader, CreateReportPlacementsList } from '.'
import {
	PlacementsWithSelectType,
	RelatedKeyType,
} from '../../../../CreateReportPage'
import useResponsive from 'src/utils/useResponsive'

type PlacementsProps = {
	outdoorPlacements: Array<PlacementsWithSelectType<string>>
	indoorPlacements: Array<PlacementsWithSelectType<string>>
	relatedKeys: Record<RelatedKeyType, boolean>
	isLoading: boolean
	handleIndoorPlacementsChange: (placementId: string) => void
	tvIsSelected: boolean
	publicTransportIsSelected: boolean
	internetIsSelected: boolean
	handleRelatedKeysChange: (relatedKey: string) => void
	handleOutdoorPlacementsChange: (placementId: string) => void
}

export const Placements = ({
	indoorPlacements,
	outdoorPlacements,
	relatedKeys,
	handleIndoorPlacementsChange,
	internetIsSelected,
	tvIsSelected,
	isLoading,
	handleOutdoorPlacementsChange,
	handleRelatedKeysChange,
	publicTransportIsSelected,
}: PlacementsProps) => {
	const { isMobile } = useResponsive()
	return (
		<>
			{(!!indoorPlacements.length ||
				!!outdoorPlacements.length ||
				tvIsSelected ||
				internetIsSelected ||
				publicTransportIsSelected) && (
				<div
					className={cn(baseStyle.createReportStep, {
						[baseStyle.createReportStep_mobile]: isMobile,
					})}
					style={{ marginTop: '20px' }}
				>
					<CreateReportBody>
						<CreateReportPlacementHeader
							isLoading={isLoading}
							title="Места размещения"
						/>

						<div>
							{indoorPlacements.length > 0 && (
								<CreateReportPlacementsList
									onChange={handleIndoorPlacementsChange}
									placements={indoorPlacements}
									title="Внутренняя реклама"
								/>
							)}
							{outdoorPlacements.length > 0 && (
								<CreateReportPlacementsList
									onChange={handleOutdoorPlacementsChange}
									placements={outdoorPlacements}
									title="Наружная реклама"
								/>
							)}
							{tvIsSelected && (
								<CreateReportPlacementsList<RelatedKeyType>
									onChange={handleRelatedKeysChange}
									placements={[
										{
											id: 'adstreamadversting',
											isSelected:
												relatedKeys.adstreamadversting,
											placement_type: {
												name: 'AdStream',
											},
										},
										{
											id: 'teletargetadversting',
											isSelected:
												relatedKeys.teletargetadversting,
											placement_type: {
												name: 'Teletarget',
											},
										},
									]}
									title="TВ-реклама"
								/>
							)}

							{internetIsSelected && (
								<CreateReportPlacementsList<RelatedKeyType>
									onChange={handleRelatedKeysChange}
									placements={[
										{
											id: 'yandexinternetadvertising',
											isSelected:
												relatedKeys.yandexinternetadvertising,
											placement_type: {
												name: 'Яндекс.Директ',
											},
										},
										{
											id: 'vkinternetadvertising',
											isSelected:
												relatedKeys.vkinternetadvertising,
											placement_type: {
												name: 'VK Реклама',
											},
										},
									]}
									title="Интернет-реклама"
								/>
							)}
							{publicTransportIsSelected && (
								<CreateReportPlacementsList<RelatedKeyType>
									onChange={handleRelatedKeysChange}
									placements={[
										{
											id: 'public_transport',
											isSelected:
												relatedKeys.public_transport,
											placement_type: {
												name: 'Цифровые экраны на МЦК',
											},
										},
									]}
									title="Общественный транспорт"
								/>
							)}
						</div>
					</CreateReportBody>
				</div>
			)}
		</>
	)
}
