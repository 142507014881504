import React, { useMemo } from 'react'
import s from './stepper.module.scss'
import useCampaignData from 'src/stores/campaignData'
import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import { AdvertisingFormatsElement } from './AdvertisingFormatsElement'

export const AdvertisingFormatsList = () => {
	const [campaign, _] = useCampaignData()

	const whichFormatsSelected = useMemo(() => {
		return checkSelectedFormats(campaign)
	}, [campaign])

	const advertisingFormatsList = useMemo(() => {
		return {
			outdoor: {
				label: 'Наружная реклама',
				selected: whichFormatsSelected.outdoor,
			},
			indoor: {
				label: 'Внутренняя реклама',
				selected: whichFormatsSelected.indoor,
			},
			publicTransport: {
				label: 'Общественный транспорт',
				selected: whichFormatsSelected.publicTransport,
			},
			internet: {
				label: 'Интернет реклама',
				selected: whichFormatsSelected.internet,
			},
			video: {
				label: 'ТВ-реклама',
				selected: whichFormatsSelected.tv,
			},
		}
	}, [campaign])

	const elements = useMemo(() => {
		return Object.keys(advertisingFormatsList).map((key) => (
			<AdvertisingFormatsElement
				key={advertisingFormatsList[key].label}
				label={advertisingFormatsList[key].label}
				isSelected={advertisingFormatsList[key].selected}
				anchor={key}
			/>
		))
	}, [campaign])

	return <ul className={s.formatList}>{elements}</ul>
}

function checkSelectedFormats(campaign: CampaignInfoTypeBookingsSeparation) {
	return {
		outdoor:
			campaign.outdoor_adv_is_selected ||
			campaign.district_adv_is_selected,
		indoor: campaign.indoor_adv_is_selected,
		publicTransport: !!campaign.public_transport?.is_selected,
		internet:
			campaign?.internet_vk?.is_selected ||
			campaign.internet_yandex?.is_selected,
		tv:
			campaign?.teletarget?.is_selected ||
			campaign?.adstream?.is_selected,
	}
}
