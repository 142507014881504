import { Formik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Dropdown, Form, Spinner } from 'react-bootstrap'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from 'src/components/UtilityComponents/ReusableModals/ReusableModalsProvider'
import { ScrollToError } from 'src/components/UtilityComponents/ScrollToFormikError'
import { InternetUploadForm } from '../../Internet/InternetUpload'
import useBidRequest from '../../CreateCreative/useBidRequest'
import useResponsive from 'src/utils/useResponsive'
import useCampaignData from 'src/stores/campaignData'
import s from '../../DataUpload.module.scss'
import { MoscowCentRingUpload } from './moscow-cent-ring-upload/MoscowCentRingUpload'
import { ReactComponent as MCR } from 'src/assets/images/metro.svg'

export const PublicTransportUpload = ({
	isLoading,
	onStepBack,
	cn,
	onBack,
	isStepper,
	setGlobalStep,
	isLastStep,
	step,
	totalSteps,
	onNext,
}: any) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [isMediaPlanLoading, setIsMediaPlanLoading] = useState(false)
	const { isMobile } = useResponsive()
	const { isHaveBidRequest } = useBidRequest()
	const showModal = useReusableModals()

	const initialValues = useMemo<InternetUploadForm>(() => {
		return {
			vk_button_text: campaign.internet_vk.button_text || '',
			vk_text: campaign.internet_vk.text || '',
			vk_image: campaign.internet_vk.image || '',
			vk_heading: campaign.internet_vk.heading || '',
			yandex_heading: campaign.internet_yandex.heading || '',
			yandex_text: campaign.internet_yandex.text || '',
			yandex_image: campaign.internet_yandex.image || '',
		}
	}, [campaign])

	const handleSubmit = async (values) => {
		onNext()
		setIsMediaPlanLoading(false)
	}
	const handleValidation = (values) => {
		const errors: any = {}

		return errors
	}

	const withErrors = useCallback(() => {
		const isUploadedMedia =
			campaign.public_transport?.image || campaign.public_transport?.video

		const isDesignerHelp = isHaveBidRequest({
			id: campaign.public_transport?.id || 0,
			type: 'public_transport',
		})
		return !isUploadedMedia && !isDesignerHelp
	}, [campaign])
	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isMediaPlanLoading) {
			timeoutId = setTimeout(() => {
				setIsMediaPlanLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isMediaPlanLoading])

	const handleWatchMediaPlan = () => {
		setIsMediaPlanLoading(true)
		showModal(REUSABLE_MODALS.showMediaPlanModal).finally(() => {
			setIsMediaPlanLoading(false)
		})
	}

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={handleValidation}
				validateOnChange={false}
				enableReinitialize={true}
			>
				{({
					values,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<Form onSubmit={handleSubmit}>
						<ScrollToError />
						<Card
							className={s.card}
							style={{ marginBottom: '8px' }}
						>
							{(!isStepper ||
								(totalSteps === 1 && step === 0)) && (
								<>
									<h2 style={{ marginBottom: '8px' }}>
										Загрузка материалов
									</h2>
									<Dropdown.Divider
										className={' p-0 m-0 mb-4'}
									/>
									<Button
										variant={'light'}
										onClick={handleWatchMediaPlan}
										className={'w-100 mb-4'}
										disabled={isMediaPlanLoading}
									>
										{isMediaPlanLoading ? (
											<>
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
													className="me-2"
												/>
												Загрузка медиаплана...
											</>
										) : (
											'Смотреть медиаплан'
										)}
									</Button>
								</>
							)}
							<div>
								<div
									className={
										'd-flex justify-content-between mt-2'
									}
								>
									<h4 className={'mb-0'}>
										Общественный транспорт
									</h4>
									<MCR />
								</div>
							</div>
						</Card>

						<Card className={s.card}>
							<MoscowCentRingUpload />
						</Card>

						<div className={cn('buttons')}>
							<Button
								variant={'primary'}
								onClick={onNext}
								style={
									isMobile
										? { width: '100%', marginLeft: '10px' }
										: {}
								}
								disabled={
									isMediaPlanLoading ||
									withErrors() ||
									isLoading
								}
							>
								Продолжить
								{isLoading && (
									<Spinner
										style={{ marginLeft: '6px' }}
										size="sm"
										animation="border"
									/>
								)}
							</Button>
							<Button
								variant={'secondary'}
								onClick={() => {
									if (isStepper) {
										return step - 1 >= 0
											? onStepBack()
											: onBack()
									}
									return onBack()
								}}
							>
								Назад
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	)
}
