import React, { FC, useMemo } from 'react'
import { Badge } from 'react-bootstrap'
import { BookingState } from '../../../../../stores/ADMarketTypes.types'

export const BookingBadge: FC<{ state: BookingState }> = ({ state }) => {
	const bg = useMemo(() => {
		switch (state) {
			case 'moderation':
				return 'primary'
			case 'inner_moderation':
				return 'primary'
			case 'confirm':
				return 'success'
			case 'adjustment':
				return 'primary'
			case 'completed':
				return 'secondary'
			case 'reject':
				return 'danger'
			case 'canceled':
				return 'danger'
		}
	}, [state])
	const text = useMemo(() => {
		switch (state) {
			case 'moderation':
				return 'На модерации'
			case 'inner_moderation':
				return 'На модерации'
			case 'confirm':
				return 'Подтверждена'
			case 'adjustment':
				return 'На исправлении'
			case 'completed':
				return 'Завершена'
			case 'reject':
				return 'Отклонена'
			case 'canceled':
				return 'Отменена'
		}
	}, [state])
	return (
		<Badge
			bg={bg}
			style={{
				// height: '18px',
				// padding: '3px 4px',
				// fontFamily: 'Inter',
				// fontStyle: 'normal',
				// fontWeight: 500,
				// fontSize: '12px',
				// lineHeight: '12px',
				// letterSpacing: '-0.05em',
				// marginLeft: '8px',
				height: 'fit-content',
			}}
		>
			{text}
		</Badge>
	)
}
