import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { YandexIcon } from './InternetSelector'
import { Button, Col, Form, Row } from 'react-bootstrap'
import useCampaignData from '../../../../../stores/campaignData'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'
import { addHttps, checkInternetURL } from './URLutils'
import { FormatConfExact } from '../../components'
import { InsideErrors } from '../ADFormatSummary'
import useDebounce from 'src/utils/useDebounce'
import { NumericFormat } from 'react-number-format'
import { useAdformatsStore } from '../adformats-store/adformats.store'
import { AnimatePresence, motion } from 'framer-motion'

interface IYandexDirectAd {
	onError: (key: keyof InsideErrors, value: boolean) => void
	children?: React.ReactNode | React.ReactNode[]
	setIsLoading: (value: boolean) => void
}

type YandexAdFields = {
	link: string
	amount: number
}
type YandexAdFieldsErrors = {
	link: string | undefined
	amount: string | undefined
}

const yandexAmountId = 'yandex_amount'
const yandexLinkId = 'yandex_link'

const YandexDirectAd: FC<IYandexDirectAd> = ({ onError, setIsLoading }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [displayAll, setDisplayAll] = useState(true)

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)

	const isYandexSelected = useAdformatsStore(
		(state) => state.yandex.isSelected
	)

	const actualAmount = useMemo(() => {
		return campaign.internet_yandex.amount
	}, [campaign])
	const actualLink = useMemo(() => {
		return campaign.internet_yandex.link
	}, [campaign])
	const minBudget = useMemo(() => {
		return campaign.internet_yandex.min_budget || 0
	}, [campaign])

	const [amount, setAmount] = useState(
		campaign.internet_yandex?.amount
			? campaign.internet_yandex?.amount.toString()
			: ''
	)
	const [link, setLink] = useState(campaign.internet_yandex.link || '')

	const debouncedAmount = useDebounce(amount, 300)
	const debouncedLink = useDebounce(link, 300)

	const [errors, setErrors] = useState<YandexAdFieldsErrors>(() => ({
		amount:
			parseFloat(amount) < minBudget
				? `Минимальный бюджет ${minBudget} ₽`
				: undefined,
		link: link ? undefined : 'Добавьте ссылку',
	}))

	const handleValidate = useCallback(
		(field: keyof YandexAdFields, value: string | number) => {
			const validateErrors: YandexAdFieldsErrors = {
				...errors,
			}

			if (field === 'link') {
				if (!value) {
					validateErrors.link = 'Добавьте ссылку'
				} else if (!checkInternetURL(value as string)) {
					validateErrors.link = 'Некорректная ссылка'
				} else {
					validateErrors.link = undefined
				}
			}
			if (field === 'amount') {
				const validValue = parseFloat(value as string)

				if (value === '') {
					validateErrors.amount = 'Добавьте бюджет'
				} else if (isNaN(validValue)) {
					validateErrors.amount = 'Некорректное значение'
				} else if (validValue < minBudget) {
					validateErrors.amount = `Минимальный бюджет ${minBudget} ₽`
				} else {
					validateErrors.amount = undefined
				}
			}
			return validateErrors
		},
		[errors, minBudget]
	)

	const updateYandex = useCallback(
		async (field: keyof YandexAdFields, value: string | number) => {
			const validationResult = handleValidate(field, value)

			if (validationResult[field]) {
				setErrors(validationResult)
				return
			}

			const formData = new FormData()
			if (field === 'link' && value !== link) {
				const linkWithProtocol = addHttps(value as string)
				formData.append(field, linkWithProtocol)
			}

			if (field === 'amount' && value !== amount) {
				formData.append(field, value.toString())
			}

			try {
				setIsLoading(true)
				await campaignInterface.patchInternetYandex(
					campaign.id,
					formData,
					false
				)
				const disableErrors: YandexAdFieldsErrors = {
					...errors,
				}
				if (field === 'amount') {
					disableErrors.amount = undefined
				}
				if (field === 'link') {
					disableErrors.link = undefined
				}
				setErrors(disableErrors)
				await campaignInterface.refetchSelected()
			} catch (error: any) {
				const validateErrors = { ...errors }

				if (error['link'])
					validateErrors.link = error['link']?.join(',')
				if (error['amount']) {
					setErrors(validateErrors)
				}
			} finally {
				setIsLoading(false)
			}
		},
		[errors, minBudget]
	)

	const handleAmountChange = useCallback((value: string) => {
		const newAmount = value?.replaceAll(' ', '')?.replace('₽', '')

		setAmount(newAmount)
	}, [])
	const handleLinkChange = useCallback((value: string) => {
		setLink(value.trim())
	}, [])

	useEffect(() => {
		if (isYandexSelected) {
			setAmount(
				campaign.internet_yandex?.amount
					? campaign.internet_yandex?.amount.toString()
					: ''
			)
		}
	}, [isYandexSelected])
	useEffect(() => {
		if (actualLink !== link) {
			updateYandex('link', debouncedLink)
		} else {
			const validateErrors = handleValidate('link', link)
			setErrors(validateErrors)
		}
	}, [debouncedLink, actualLink])

	useEffect(() => {
		if (actualAmount !== parseFloat(amount)) {
			updateYandex('amount', debouncedAmount)
		} else {
			const validateErrors = handleValidate('amount', amount)
			setErrors(validateErrors)
		}
	}, [debouncedAmount, actualAmount])

	useEffect(() => {
		if (isYandexSelected) {
			if (!!errors.amount) {
				addNewAdFormatError('yandex', {
					field: 'Бюджет',
					id: yandexAmountId,
					message: errors.amount || 'Ошибка в бюджете',
				})
			}
			if (!!errors.link) {
				addNewAdFormatError('yandex', {
					field: 'Сайт',
					id: yandexLinkId,
					message: errors.link || 'Проверьте адрес сайта',
				})
			}
		}
		return () => {
			setAdFormatErrors('yandex', null)
		}
	}, [errors, isYandexSelected])
	return (
		<Form className={'d-flex flex-column gap-4'}>
			<div className={'d-flex justify-content-between'}>
				<h5 className={'mb-0'}>Яндекс</h5>
				<div>
					<YandexIcon />
				</div>
			</div>
			<Form.Group>
				<ul className={'d-flex flex-column gap-2'}>
					<li>
						Вам понадобится рекламное изображение. Если у вас его
						нет — мы поможем с изготовлением. Соответствующая опция
						появится на следующем этапе «Загрузка материалов».
					</li>
					<li>
						Мы проведём единую перфоманс-кампанию, которая подходит
						для размещения рекламы большинства товаров и услуг в
						разных форматах. Она объединяет и расширяет возможности
						самых популярных и привычных кампаний Директа.
						Объявления показываются в результатах поиска и в
						Рекламной сети Яндекса, на десктопах, планшетах и
						мобильных телефонах.
					</li>
					<li>
						Аудитория увидит вашу рекламу в Рекламной сети Яндекса
						(РСЯ) — это система размещения рекламы на сервисах
						Яндекса и площадках партнеров. РСЯ открывает
						рекламодателям доступ к аудитории более чем 50 000
						площадок. Полный список площадок вы можете посмотреть в
						специальном разделе на сайте{' '}
						<a
							href="https://yandex.ru/support/direct/general/yan.html"
							target={'_blank'}
							rel={'noreferrer'}
						>
							Яндекс.
						</a>
					</li>
					<li>
						Минимальная стоимость рекламной кампании напрямую
						зависит от выбранного периода (количества дней).
					</li>
				</ul>
			</Form.Group>

			<YandexCounterId setIsLoading={setIsLoading} />
			<Form.Group>
				<Form.Label>Рекламируемый сайт</Form.Label>
				<Form.Control
					id={yandexLinkId}
					type={'text'}
					size={'lg'}
					value={link}
					onChange={(e) => handleLinkChange(e.target.value)}
					isInvalid={!!errors.link}
					placeholder={'Введите ссылку на сайт'}
				/>
				<Form.Control.Feedback type={'invalid'}>
					{errors.link}
				</Form.Control.Feedback>
			</Form.Group>

			{displayAll && (
				<>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Бюджет кампании</Form.Label>
							<NumericFormat
								id={yandexAmountId}
								decimalScale={0}
								value={amount}
								suffix=" ₽"
								thousandSeparator={' '}
								customInput={InputWithEndText}
								onChange={(e) =>
									handleAmountChange(e.target.value)
								}
								size={'lg'}
								isInvalid={!!errors.amount}
								FeedBackComponent={
									<Form.Control.Feedback type="invalid">
										{errors.amount}
									</Form.Control.Feedback>
								}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Оплата</Form.Label>
							<Form.Control
								type={'text'}
								value={'За клики'}
								disabled
								size={'lg'}
							/>
						</Form.Group>
					</Row>

					<Form.Group>
						<FormatConfExact
							isValid={!errors.amount && !errors.link}
						/>
					</Form.Group>
				</>
			)}
		</Form>
	)
}

type YandexCounterIdProps = {
	setIsLoading: (value: boolean) => void
}
export const YandexCounterId = ({ setIsLoading }: YandexCounterIdProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [open, setOpen] = useState(!!campaign.internet_yandex.counter_id)
	const [error, setError] = useState('')
	const [counterIdChanged, setCounterIdChanged] = useState(false)

	const [counterId, setCounterId] = useState(
		campaign.internet_yandex.counter_id
	)
	const debouncedCounterId = useDebounce(counterId, 400)

	const patchCounterId = async () => {
		const formData = new FormData()

		formData.append('counter_id', debouncedCounterId)
		setIsLoading(true)
		try {
			await campaignInterface.patchInternetYandex(
				campaign.id,
				formData,
				false
			)
			setError('')
		} catch (error: any) {
			if (error['counter_id'] && Array.isArray(error['counter_id'])) {
				setError(error['counter_id']?.join(','))
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleCounterIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCounterIdChanged(true)
		setCounterId(e.currentTarget.value.trim())
	}
	useEffect(() => {
		if (counterIdChanged) {
			patchCounterId()
		}
	}, [debouncedCounterId])
	return (
		<div>
			<AnimatePresence>
				{open ? (
					<Form.Group
						as={motion.div}
						initial={{ opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<div className="d-flex justify-content-between align-items-center py-2">
							<p className="m-0">Яндекс.Метрика ID</p>
							{open && (
								<Button
									onClick={() => setOpen(false)}
									variant="text"
									className="p-0"
								>
									Свернуть
								</Button>
							)}
						</div>

						<NumericFormat
							placeholder={''}
							decimalScale={0}
							value={counterId}
							customInput={InputWithEndText}
							onChange={(e) => handleCounterIdChange(e)}
							size={'lg'}
							isInvalid={!!error}
							FeedBackComponent={
								<Form.Control.Feedback type="invalid">
									{error}
								</Form.Control.Feedback>
							}
						/>
					</Form.Group>
				) : (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<Button
							onClick={() => setOpen(!open)}
							className="d-flex align-items-center gap-4"
						>
							<p className="m-0">Есть Яндекс.Метрика?</p>
						</Button>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}
export default YandexDirectAd
