import React, { FC, useMemo } from 'react'
import s from './list.module.scss'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import declOfNum from '../../../../../utils/declOfNum'
import useProviderData from '../../../../../stores/providerData'
import { BookingListItem } from '../../../../../stores/ProviderTypes.types'
import { BookingState } from '../../../../../stores/ADMarketTypes.types'
import useBookingsOperations from '../../context/useBookingsOperations'
import { useBookingsContext } from '../../context/BookingsProvider'

interface ISelectedOperations {
	groupSelected: number[]
}

const animationVariants = {
	initial: { opacity: 0, bottom: -50 },
	animate: { opacity: 1, bottom: 10 },
	exit: { opacity: 0, bottom: -50 },
}
const spring = {
	type: 'spring',
	damping: 20,
	stiffness: 100,
	duration: 0.4,
}

const SelectedOperations: FC<ISelectedOperations> = ({ groupSelected }) => {
	const [provider] = useProviderData()

	const isEditButtonsAllowed = useMemo(() => {
		if (!provider?.bookings?.results) return false
		const all_bookings = [...provider?.bookings?.results]
		const selected_bookings: BookingListItem[] = all_bookings.filter(
			(booking) => groupSelected.includes(booking.id)
		)
		let tmp = true
		selected_bookings.forEach((booking) => {
			booking.state !== BookingState.Moderation &&
			booking.state !== BookingState.Inner_Moderation
				? (tmp = false)
				: null
		})
		return tmp
	}, [provider.bookings, groupSelected])

	const { buttons } = useBookingsOperations({
		bookingInModeration: isEditButtonsAllowed,
	})
	const { onActionButtonClick } = useBookingsContext()

	return (
		<AnimatePresence exitBeforeEnter>
			{groupSelected.length !== 0 && (
				<motion.div
					initial="initial"
					animate="animate"
					exit="exit"
					variants={animationVariants}
					transition={spring}
					style={{
						position: 'absolute',
						width: '100%',
						pointerEvents: 'none',
					}}
				>
					<div className={s.selectedOperations}>
						<div
							className={
								'd-flex align-items-center text-nowrap me-2'
							}
						>
							<svg
								width="13"
								height="14"
								viewBox="0 0 13 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className={'me-2'}
							>
								<rect
									x="0.5"
									y="1"
									width="12"
									height="12"
									rx="1.5"
									fill="#007BFF"
									stroke="#007BFF"
								/>
								<path
									d="M3 7.5L5 9.5L10.5 3.5"
									stroke="white"
									strokeWidth="2"
								/>
							</svg>
							{`${groupSelected.length} ${declOfNum(
								groupSelected.length,
								['бронь', 'брони', 'броней']
							)}`}
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default SelectedOperations
