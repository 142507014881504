import React, { useMemo } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import { useAdformatsStore } from '../../../adformats-store/adformats.store'
import { getAdformatsErrorsState } from '../../../adformats-store/adformats-store-selectors/getAdformatsErrorsState'
import s from '../../../ADFormatSummary.module.scss'

type Props = {
	isButtonsDisabled: boolean
	isLoading: boolean
	onSubmit: () => void
	isBudgetConfirmed: boolean
}
export const ConfirmBudgetButton = ({
	isButtonsDisabled,
	isLoading,
	onSubmit,
	isBudgetConfirmed,
}: Props) => {
	const { isMobile } = useResponsive()

	const errors = useAdformatsStore(getAdformatsErrorsState)

	const errorsCount = useMemo(() => {
		return Object.values(errors).reduce((acc, curr) => {
			return acc + (curr ? curr.length : 0)
		}, 0)
	}, [errors])
	return (
		<Button
			variant={'primary'}
			style={{
				width: isMobile ? '100%' : 'auto',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			onClick={onSubmit}
			disabled={!isButtonsDisabled || isLoading || errorsCount > 0}
		>
			<div className={s.btnContain}>
				{isBudgetConfirmed ? 'Продолжить' : 'Подтвердить бюджет'}
			</div>
			{isLoading && (
				<Spinner
					style={{ marginLeft: '6px' }}
					size="sm"
					animation="border"
				/>
			)}
		</Button>
	)
}
