import React from 'react'
import s from './stepper.module.scss'

type AdvertisingFormatsElementPropsType = {
	label: string
	isSelected: boolean
	anchor: string
}
export const AdvertisingFormatsElement = (
	props: AdvertisingFormatsElementPropsType
) => {
	const activeStyle = props.isSelected ? 'active' : ''

	const handleClick = (event) => {
		event.preventDefault()

		const targetId = event.currentTarget.getAttribute('href')
		const targetElement = document.querySelector(targetId)

		if (targetElement) {
			targetElement.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			})
		}
	}

	return (
		<li className={s.formatElement}>
			<div className={s.submenuItem} data-active={activeStyle}>
				<div className={s.circle} />

				<a href={'#' + props.anchor} onClick={handleClick}>
					{props.label}{' '}
				</a>
			</div>
		</li>
	)
}
