import React from 'react'
import { Card } from 'react-bootstrap'
import { InsideErrors } from '../../../ADFormatSummary'
import { Loader } from 'src/components/_provider/Bookings/content/list/Loader'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import s from './mcr.module.scss'
import { FormatConfExact } from 'src/components/_client/create-campaign/components'
import { MoscowCentRingControls } from './MoscowCentRingControls'
type Props = {
	cn: (className: string) => string
	isLoading: boolean
	setGlobalIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	onError: (key: keyof InsideErrors, value: boolean) => void
}
export const MoscowCentRing = ({
	cn,
	isLoading,
	onError,
	setGlobalIsLoading,
}: Props) => {
	return (
		<>
			<Card className={cn('form')}>
				{isLoading ? (
					<Loader />
				) : (
					<div className={s.ourdoorMassCoverage}>
						<div className={s.ourdoorMassCoverageHeader}>
							<div
								className={s.ourdoorMassCoverageHeader_primary}
							>
								<h5>Цифровые экраны на МЦК</h5>
								<MCR />
							</div>
						</div>
						<ul>
							<li>
								Алгоритмический охват: автоматическое
								распределение показов по всем 64 поверхностям на
								30 станциях. Техническая возможность таргетинга
								на конкретные станции прорабатывается оператором
								рекламных экранов и будет доступна позже.
							</li>

							<li style={{ marginTop: '0.5rem' }}>
								Укажите свой бюджет и мы подготовим точный
								расчет вашей рекламной кампании.
							</li>
						</ul>
						<MoscowCentRingControls
							onError={onError}
							setIsLoading={setGlobalIsLoading}
						/>
					</div>
				)}
			</Card>
		</>
	)
}
