import React, { FC, useMemo, useState } from 'react'
import s from './list.module.scss'
import { useBookingsContext } from '../../context/BookingsProvider'
import useProviderData from '../../../../../stores/providerData'
import { Spinner } from 'react-bootstrap'
import ListHeader from './ListHeader'
import BookingItem from './BookingItem'
import SelectedOperations from './SelectedOperations'
import { Loader } from './Loader'
import { BookingDetails } from 'src/stores/ProviderTypes.types'
import useResponsive from 'src/utils/useResponsive'
import cn from 'classnames'

type FilterValues = null | 'asc' | 'desc'
type BookingsListType = {
	setSelectedBookingId: React.Dispatch<
		React.SetStateAction<number | undefined>
	>
}

const BookingsList: FC<BookingsListType> = ({ setSelectedBookingId }) => {
	const [provider] = useProviderData()
	const { isListFetching } = useBookingsContext()

	const [groupSelected, setGroupSelected] = useState<number[]>([])

	const { isMobile } = useResponsive()

	const currentBookings = useMemo(() => {
		if (!provider?.bookings?.results) return []
		setGroupSelected([])
		return provider.bookings.results
	}, [provider])

	const onCheckBoxClick = (id: number) => {
		if (groupSelected.includes(id)) {
			return setGroupSelected((p) => p.filter((v) => v !== id))
		}
		return setGroupSelected((p) => [...p, id])
	}

	const onAllCheckBoxClick = () => {
		if (currentBookings.length === 0) return
		if (currentBookings.length === groupSelected.length) {
			setGroupSelected([])
		} else {
			setGroupSelected([])
			currentBookings.forEach((booking) => {
				setGroupSelected((p) => [...p, booking.id])
			})
		}
	}

	return (
		<div
			className={cn(s.listContainer, {
				[s.listContainer_mobile]: isMobile,
			})}
		>
			{isListFetching ? (
				<Loader />
			) : (
				<>
					{!isMobile && (
						<ListHeader
							checked={
								groupSelected.length === currentBookings.length
							}
							onToggle={onAllCheckBoxClick}
						/>
					)}
					<ul style={{ flex: '1 1 auto' }}>
						{currentBookings?.map((booking) => (
							<BookingItem
								setSelectedBookingId={setSelectedBookingId}
								booking={booking}
								groupSelected={groupSelected}
								key={booking?.id}
								onCheckBoxClick={onCheckBoxClick}
							/>
						))}
					</ul>
					<SelectedOperations groupSelected={groupSelected} />
				</>
			)}
		</div>
	)
}

export default BookingsList
