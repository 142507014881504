import React, { useEffect, useState } from 'react'
import { DistrictType } from '../../types'
import useCampaignData from 'src/stores/campaignData'
import { Card } from 'react-bootstrap'
import { Loader } from 'src/components/_provider/Bookings/content/list/Loader'
import { MassCoverageItemsList } from './mass-coverage-items-list/MassCoverageItemsList'
import { FormatConfExact } from 'src/components/_client/create-campaign/components'
import { ReactComponent as Radius } from 'src/assets/images/radius.svg'
import s from './ourdoorMassCoverage.module.scss'
import { InsideErrors } from '../../../ADFormatSummary'
import { useAdformatsStore } from '../../../adformats-store/adformats.store'

type OutdoorCityMethodProps = {
	title: string
	cn: (className: string) => string
	isLoading: boolean
	districts: Array<DistrictType>
	setGlobalIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	onError: (key: keyof InsideErrors, value: boolean) => void
}

const massCoverageNoItemsErrorId = 'massCoverage_no_items'
export const OutdoorMassCoverage = ({
	cn,
	districts,
	isLoading,
	setGlobalIsLoading,
}: OutdoorCityMethodProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)
	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)

	const [isTemplate, setIsTemplate] = useState(() => {
		return campaign.districtbookings.length === 0
	})

	useEffect(() => {
		if (campaign.districtbookings.length === 0 && !isTemplate) {
			addNewAdFormatError('massCoverage', {
				field: 'Не добавлены города',
				id: massCoverageNoItemsErrorId,
				message: 'Не добавлены города',
			})
		} else {
			removeAdFormatErrorByErrorId(
				'massCoverage',
				massCoverageNoItemsErrorId
			)
		}
	}, [campaign.districtbookings.length, isTemplate])
	return (
		<>
			<Card className={cn('form')} id={massCoverageNoItemsErrorId}>
				{isLoading ? (
					<Loader />
				) : (
					<div className={s.ourdoorMassCoverage}>
						<div className={s.ourdoorMassCoverageHeader}>
							<div
								className={s.ourdoorMassCoverageHeader_primary}
							>
								<h5>Mаксимальный охват</h5>
								<Radius />
							</div>
							<p>
								Динамическое распределение показов в рамках
								бюджета рекламной кампании по всем свободным
								экранам в выбранных городах.
							</p>
						</div>

						<MassCoverageItemsList
							isTemplate={isTemplate}
							setIsTemplate={setIsTemplate}
							setGlobalIsLoading={setGlobalIsLoading}
							districtbookings={campaign.districtbookings}
							districts={districts}
						/>
					</div>
				)}
			</Card>
		</>
	)
}
