import React, { FC, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import PaymentSteps, { IPaymentSteps } from './PaymentSteps'
import useCampaignData from '../../../../../stores/campaignData'

interface IPaymentChooseModal {
	show: boolean
	handleClose: () => void
}
export type PaymentChooseFormType = {
	method: 'PAYMENT' | 'INVOICE' | null
	closed_documents: 'EDO' | 'LK' | null
}
const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

const PaymentChooseModal: FC<IPaymentChooseModal> = ({ show, handleClose }) => {
	const [step, setStep] = useState<number>(0)
	const [campaign, campaignInterface] = useCampaignData()
	const initialValues = useMemo<PaymentChooseFormType>(
		() => ({
			method: null,
			closed_documents: null,
		}),
		[]
	)
	const CurrentStepComponent = useMemo<FC<IPaymentSteps>>(
		() => PaymentSteps[step],
		[step]
	)
	const onNextStep = () => setStep((p) => p + 1)
	const onSubmit = async (values: PaymentChooseFormType, helpers) => {
		if (values.method === 'PAYMENT') {
			campaignInterface.createPayment().then((res) => {
				window.location.href = res.payment_url
			})
		} else {
			onNextStep()
		}
	}
	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{({ values, handleSubmit, setFieldValue, isSubmitting }) => (
				<Modal
					show={show}
					onHide={handleClose}
					centered
					backdrop="static"
					keyboard={false}
				>
					<CurrentStepComponent
						values={values}
						setFieldValue={setFieldValue}
						handleSubmit={handleSubmit}
						onNextStep={onNextStep}
						isSubmitting={isSubmitting}
						closeModal={handleClose}
					/>
				</Modal>
			)}
		</Formik>
	)
}

export default PaymentChooseModal
