import React from 'react'
import { Button } from 'react-bootstrap'
import { ReactComponent as PinOnMap } from 'src/assets/images/pin-2.svg'
import { ReactComponent as Edit } from 'src/assets/images/edit.svg'
import s from './massCoverageItem.module.scss'
import { formattedNumber } from 'src/utils'

type Props = {
	errors: {
		city: string | undefined
		budget: string | undefined
	}
	onMapOpen: () => void
	onDelete: () => void
	count: number
	districtBookingId: string
}
export const OpenMapButton = ({
	errors,
	onMapOpen,
	count,
	districtBookingId,
}: Props) => {
	return (
		<div
			className={s.openMapButtonWrapper}
			id={`${districtBookingId}_placementsCount`}
		>
			{count > 0 && (
				<h6>{`Добавлено мест: ${formattedNumber(count)}`}</h6>
			)}
			<div>
				<Button
					variant="text"
					disabled={!!errors.budget || !!errors.city}
					className={s.openMapButton}
					onClick={onMapOpen}
				>
					{count > 0 ? <Edit /> : <PinOnMap />}

					<p>{count > 0 ? 'Изменить' : 'Уточнить места'}</p>
				</Button>
				{count === 0 && !errors.budget && !errors.city && (
					<div
						className="text-danger"
						style={{ fontSize: '12px', marginTop: '0.25rem' }}
					>
						Выберите места
					</div>
				)}
			</div>
		</div>
	)
}
