import React from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import useCampaignData from '../../../../stores/campaignData'
import IntlFormatter from '../../../../utils/intl-money-formatter'

export const budgetZero = '0.00'

const Budget = (props) => {
	const [campaign] = useCampaignData()

	return (
		<div>
			<>
				{(campaign.budget.outdoor !== budgetZero ||
					campaign.budget.district !== budgetZero) && (
					<>
						<Row>
							<Col as={'h6'} className={'mb-0'}>
								Наружная реклама:
							</Col>
							<Col className={'text-end mb-0'} as={'h6'}>
								{IntlFormatter.format(campaign.budget.outdoor)}
							</Col>
						</Row>
						{<Dropdown.Divider className={'m-0 mt-2 mb-2'} />}
					</>
				)}
				{campaign.budget.indoor !== budgetZero && (
					<>
						<Row>
							<Col as={'h6'} className={'mb-0'}>
								Внутренняя реклама:
							</Col>
							<Col className={'text-end mb-0'} as={'h6'}>
								{IntlFormatter.format(campaign.budget.indoor)}{' '}
							</Col>
						</Row>
						{<Dropdown.Divider className={'m-0 mt-2 mb-2'} />}
					</>
				)}
			</>
			{campaign.budget.transport !== budgetZero && (
				<>
					<Row>
						<Col as={'h6'} className={'mb-0'}>
							Общественный транспорт:
						</Col>
						<Col className={'text-end mb-0'} as={'h6'}>
							{IntlFormatter.format(campaign.budget.transport)}{' '}
						</Col>
					</Row>
					{<Dropdown.Divider className={'m-0 mt-2 mb-2'} />}
				</>
			)}
			{campaign?.budget?.internet !== budgetZero && (
				<>
					<Row>
						<Col as={'h6'} className={'mb-0'}>
							Интернет-реклама:
						</Col>
						<Col className={'text-end mb-0'} as={'h6'}>
							{IntlFormatter.format(campaign.budget.internet)}{' '}
						</Col>
					</Row>
					{<Dropdown.Divider className={'m-0 mt-2 mb-2'} />}
				</>
			)}
			{(campaign?.budget?.banner !== budgetZero ||
				campaign.budget.channel !== budgetZero) && (
				<>
					<Row>
						<Col as={'h6'} className={'mb-0'}>
							ТВ-реклама:
						</Col>
						<Col className={'text-end mb-0'} as={'h6'}>
							{IntlFormatter.format(campaign.budget.tv)}{' '}
						</Col>
					</Row>
					{<Dropdown.Divider className={'m-0 mt-2 mb-2'} />}
				</>
			)}
			<Row>
				<Col as={'h5'} className={'mb-0'}>
					Итого:
				</Col>
				<Col className={'text-end mb-0'} as={'h5'}>
					{IntlFormatter.format(campaign.budget.total)}{' '}
				</Col>
			</Row>
		</div>
	)
}

export default Budget
