import { Formik } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useCampaignData from '../../../../../stores/campaignData'
import useResponsive from '../../../../../utils/useResponsive'
import { Form } from 'react-bootstrap'
import { BannerIcon } from './TVSelector'
import useDebounce from '../../../../../utils/useDebounce'
import bannerVariants from './bannerVariants.data'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'

const TVbanner = ({ onError }: { onError: (error) => void }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	const [formValues, setFormValues] = useState<{} | null>(null)
	const debouncedFormValues = useDebounce(formValues, 1000)
	const setFieldErrorRef = useRef()

	function handleValidate(values) {
		const errors: any = {}
		if (!values.is_l_banner && !values.is_small_banner) {
			errors['is_l_banner'] =
				'Необходимо выбрать хотя бы один тип баннера'
		}
		if (Object.keys(errors).length === 0) setFormValues(values)
		return errors
	}

	useEffect(() => {
		if (!debouncedFormValues) return
		saveFilters(debouncedFormValues, setFieldErrorRef.current).then()
	}, [debouncedFormValues])

	async function saveFilters(values, setFieldError) {
		const errors = await campaignInterface.saveTeletargetFilters(
			campaign?.id,
			values
		)
		if (errors && setFieldError) {
			for (const [key, value] of Object.entries(errors)) {
				setFieldError(key as any, value as any)
			}
		}
	}

	const margin = useMemo(() => (isMobile ? '16px' : '24px'), [isMobile])

	return (
		<Formik
			initialValues={{
				is_small_banner: campaign?.teletarget?.is_small_banner || false,
				is_l_banner: campaign?.teletarget?.is_l_banner || false,
				amount: campaign.teletarget?.amount
					? parseInt(campaign.teletarget.amount).toString()
					: '',
			}}
			onSubmit={() => {}}
			validate={handleValidate}
			validateOnChange={true}
			enableReinitialize
		>
			{({
				setFieldValue,
				values,
				handleSubmit,
				errors,
				setFieldError,
			}: any) => {
				setFieldErrorRef.current = setFieldError
				return (
					<Form onSubmit={handleSubmit}>
						<div
							className={'d-flex justify-content-between'}
							style={{ marginBottom: '24px' }}
						>
							<h5 className={'mb-0'}>
								Показ всплывающего баннера при просмотре канала
							</h5>
							<div style={isMobile ? { marginLeft: '5px' } : {}}>
								<BannerIcon />
							</div>
						</div>
						<Form.Group>
							<ul className={'d-flex flex-column gap-2'}>
								<li>
									Вам понадобиться рекламный баннер. Если у
									вас его нет – мы поможем с изготовлением.
									Соответствующая опция появится на следующем
									этапе «Загрузка рекламных материалов»
								</li>
								<li>
									Показ рекламы происходит во время операций
									зрителя с пультом, непосредственного в
									момент контакта с контентом (экраном). Что
									дает гарантированный просмотр.
								</li>
								<li>
									Например при бюджете 50 000 ₽ на 2 недели
									вашу рекламу покажут примерно 166 000 раз
									около 11 800 потенциальных клиентов. А при
									бюджете 100 000 ₽ на 2 недели — около 333
									000 показов рекламы примерно для 24 000
									потенциальных клиентов.
								</li>
							</ul>
						</Form.Group>
						<Form.Group style={{ marginBottom: margin }}>
							<Form.Label>Формат баннера</Form.Label>
							<div className={'d-flex flex-column gap-4'}>
								{Object.entries(bannerVariants).map(
									([key, bV]) => {
										return (
											<div
												className={'d-flex gap-2'}
												key={key}
											>
												<Form.Check
													type={'checkbox'}
													label={``}
													id={bV.title}
													checked={
														key === 'small_banner'
															? values.is_small_banner
															: values.is_l_banner
													}
													onChange={() => {}}
													onClick={() => {
														setFieldError(
															'is_l_banner',
															undefined
														)
														if (
															'small_banner' ===
															key
														) {
															setFieldValue(
																'is_small_banner',
																!values.is_small_banner
															)
														} else {
															setFieldValue(
																'is_l_banner',
																!values.is_l_banner
															)
														}
													}}
												/>
												<div
													className={
														'd-flex flex-column gap-1'
													}
												>
													<p className={'m-0'}>
														{bV.title}
													</p>
													<span
														className={
															'text-secondary'
														}
													>
														{bV.desc}
													</span>
													<img
														src={bV.image}
														className={
															'rounded mt-2'
														}
														alt={key}
														style={{
															maxWidth: isMobile
																? '160px'
																: '320px',
														}}
													/>
												</div>
											</div>
										)
									}
								)}
							</div>
						</Form.Group>
						{errors.is_l_banner && (
							<div
								className={'invalid-feedback'}
								style={{
									display: 'block',
									marginBottom: '16px',
								}}
							>
								{errors.is_l_banner}
							</div>
						)}

						<Form.Group>
							<Form.Label>Бюджет</Form.Label>
							<InputWithEndText
								type={'number'}
								className={`${isMobile ? 'w-100' : 'w-50'}`}
								value={values.amount}
								onChange={(e) => {
									setFieldError('amount', undefined)
									setFieldValue('amount', e.target.value)
								}}
								size={'lg'}
								endText={'₽'}
								name={'amount'}
								isInvalid={!!errors.amount}
								FeedBackComponent={
									<Form.Control.Feedback type="invalid">
										{errors.amount}
									</Form.Control.Feedback>
								}
							/>
						</Form.Group>
					</Form>
				)
			}}
		</Formik>
	)
}

export default TVbanner
