import React, { useEffect, useMemo, useState } from 'react'
import { MassCoverageYandexMap } from './MassCoverageYandexMap'
import { Modal } from 'react-bootstrap'
import { PlacementShort, PlacementType } from 'src/stores/ADMarketTypes.types'
import { getDistance } from 'src/utils'
import { authFetch } from 'src/utils/authFetch'
import {
	getAddressSuggestionsWithCity,
	getPlacementInfo,
} from 'src/constants/api'
import useCampaignData from 'src/stores/campaignData'
import { CreateMassCoverageDistrictBookingBody } from 'src/stores/types/districtBookingTypes'
import { MapDataType } from '../../../types'
import { MassCoverLeftPannel } from '../left-panel'
import { MassCoverageRightPanel } from '../right-panel'
import useResponsive from 'src/utils/useResponsive'
import { MassCoverageMobileModal } from './mobile-modal'
import useDebounce from 'src/utils/useDebounce'
import getPlacementIcon from '../../../../BookingsAD/PlacementPicker/getPlacementIcon'

export type Center = {
	lat: number
	lon: number
}
export type Address = {
	address: string
	lat: string
	lon: string
}
type Props = {
	onSubmit: (
		body: CreateMassCoverageDistrictBookingBody,
		districtBookingId: number | undefined
	) => void
	open: boolean
	onClose: () => void
	mapData: MapDataType
}
export type Placemark = {
	type: string
	id: number
	geometry: {
		type: string
		coordinates: [number, number]
	}
}
const getPlacementsInDistance = (
	center: Center,
	radius: number,
	placements: PlacementShort[]
) => {
	return placements.filter((el) => {
		const distance = getDistance(center.lat, center.lon, el.lat, el.lon)
		return distance <= radius
	})
}

//return true if placements the same
const comparePlacements = (
	placementsInDistance: PlacementShort[],
	addedPlacements: PlacementShort[]
): boolean => {
	if (placementsInDistance.length !== addedPlacements.length) return false
	for (let i = 0; i < placementsInDistance.length; i++) {
		const theSameItem = addedPlacements.find(
			(el) => el.pk === placementsInDistance[i].pk
		)
		if (!theSameItem) return false
	}
	return true
}
export const MassCoverageModalMap = ({
	onClose,
	open,
	mapData,
	onSubmit,
}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()

	const {
		placements,
		currentPlacements,
		radius: defaultRadius,
		amount,
		cityId,
		districtBookingId,
	} = mapData

	const [center, setCenter] = useState<Center>({
		lat: mapData.center[0],
		lon: mapData.center[1],
	})

	const [radius, setRadius] = useState(defaultRadius)
	const debouncedRadius = useDebounce(radius, 300)

	const [circle, setCircle] = useState<any>(null)
	const [placementsInDistance, setPlacementsInDistance] = useState(() =>
		getPlacementsInDistance(center, radius, mapData.placements)
	)
	const [addedPlacements, setAddePlacements] = useState<PlacementShort[]>([])
	const [searchString, setSearchString] = useState('')
	const debouncedSearchString = useDebounce(searchString, 300) as string
	const [focusCenter, setFocusCenter] = useState<Center | null>(null)

	const [searchAddresses, setSearchAddresses] = useState<Address[] | null>(
		null
	)
	const [isRigthPanelExpanded, setIsRightPanelExpanded] = useState(false)

	const findAddresses = async (address: string) => {
		const result = await authFetch<Address[]>({
			url: getAddressSuggestionsWithCity.params(mapData.cityName),
			method: 'GET',
			query: { query: `${address}` },
		})
		setSearchAddresses(result)
	}

	const handleSearchStringChange = (searchString: string) => {
		if (searchString.trim() === '') {
			setSearchString(searchString.trim())
			setSearchAddresses(null)
			return
		}
		setSearchString(searchString)
	}

	const handleSubmit = async () => {
		const body: CreateMassCoverageDistrictBookingBody = {
			amount,
			city_id: cityId,
			center_lat: String(center.lat),
			center_lon: String(center.lon),
			placement_ids: addedPlacements.map((el) => el.pk),
			radius: String(radius.toFixed(2)),
		}

		onSubmit(body, districtBookingId)
	}

	//отвечает за появление кнопки "удалить все" в левой панели
	const isPlacementsTheSame = useMemo(
		() => comparePlacements(placementsInDistance, addedPlacements),
		[placementsInDistance, addedPlacements]
	)

	const movePlacementsToAnotherList = () => {
		if (isPlacementsTheSame) {
			setAddePlacements([])
			return
		}
		setAddePlacements(placementsInDistance)
		setIsRightPanelExpanded(true)
	}

	const [detailedPlacement, setDetailedPlacement] = useState<
		PlacementType | undefined
	>(undefined)

	const items: Array<[number, number]> = placements.map((el) => [
		+el.lat,
		+el.lon,
	])

	const objectManagerJSON = useMemo(() => {
		const placemarks: Placemark[] = []

		for (let i = 0; i < placements.length; i++) {
			const placemark = {
				type: 'Feature',
				id: placements[i].pk || 0,
				geometry: {
					type: 'Point',
					coordinates: [+placements[i].lat, +placements[i].lon] as [
						number,
						number
					],
				},

				properties: {
					icon: getPlacementIcon(
						placements[i]?.image_type,
						placements[i]?.is_active
					),
					id: placements[i].pk || 0,
					placement_type_name: placements[i].placement_type_name,
					size: placements[i].size,
				},
			}
			placemarks.push(placemark)
		}

		return {
			type: 'FeatureCollection',
			features: placemarks,
		}
	}, [])

	const fetchDetailedPlacement = async (placement: PlacementShort) => {
		const res = await authFetch<PlacementType>({
			url: getPlacementInfo.params(campaign.id, placement.pk),
			method: 'GET',
		})
		setDetailedPlacement(res)
	}

	const placementsForRender = useMemo(() => {
		// if (searchPlacements === null) {
		// 	return placementsInDistance
		// }
		// return searchPlacements

		return placementsInDistance
	}, [placementsInDistance])

	const handleItemClick = async (placementId: number) => {
		const placement = placements.find((el) => el.pk === placementId)

		if (placement) {
			await fetchDetailedPlacement(placement)
		}
	}
	const handleRemovePlacement = (itemId: number) => {
		setAddePlacements(addedPlacements.filter((el) => el.pk !== itemId))
	}
	const handleAddPlacement = (placementId: number) => {
		const placement = placements.find((el) => el.pk === +placementId)

		if (placement) {
			setAddePlacements([placement, ...addedPlacements])
		}
	}

	const handleAddressClick = (address: Address) => {
		const newCenter = { lat: +address.lat, lon: +address.lon }
		setCenter(newCenter)
		setFocusCenter(newCenter)
	}

	useEffect(() => {
		if (detailedPlacement) {
			setFocusCenter({
				lat: +detailedPlacement.lat,
				lon: +detailedPlacement.lon,
			})
		} else {
			setFocusCenter(null)
		}
	}, [detailedPlacement])
	useEffect(() => {
		if (debouncedSearchString.trim() !== '')
			findAddresses(debouncedSearchString)
	}, [debouncedSearchString])

	useEffect(() => {
		const newPlacementsInDistance = getPlacementsInDistance(
			center,
			radius,
			placements
		)

		let addedPlacementsInDistance: PlacementShort[] = []
		for (const addedPlacement of addedPlacements) {
			if (
				newPlacementsInDistance.some(
					(el) => el.pk === addedPlacement.pk
				)
			) {
				addedPlacementsInDistance.push(addedPlacement)
			}
		}

		if (detailedPlacement) {
			//Определить есть ли детальный объект в новом радиусе
			const stillContained = newPlacementsInDistance.some(
				(el) => el.pk === detailedPlacement.id
			)
			//закрыть детальную информацию если объект уже не в радиусе
			if (!stillContained) {
				setDetailedPlacement(undefined)
			}
		}
		setPlacementsInDistance(newPlacementsInDistance)
	}, [center, debouncedRadius])

	useEffect(() => {
		if (circle) {
			circle.geometry.setRadius(radius)

			const [lat, lon] = circle.geometry.getCoordinates()

			if (lon !== center.lon || lat !== center.lat) {
				circle.geometry.setCoordinates([center.lat, center.lon])
			}
		}
	}, [radius, center])
	useEffect(() => {
		const placementShort: PlacementShort[] = currentPlacements.map((el) => {
			return {
				door_type: el.door_type,
				lat: el.lat,
				lon: el.lon,
				name: el.name,
				image_type: 'billboard',
				is_active: el.is_active,
				pk: el.id,
				placement_type_name: 'Экран на улице',
				size: el.size,
				image_url: el.image,
			}
		})
		setAddePlacements(placementShort)
	}, [])

	return (
		<>
			{isMobile ? (
				<MassCoverageMobileModal
					searchString={searchString}
					onAddressClick={handleAddressClick}
					searchAddresses={searchAddresses}
					onAddItem={handleAddPlacement}
					center={center}
					focusCenter={focusCenter}
					objectManagerJSON={objectManagerJSON}
					onClose={onClose}
					open={open}
					onItemClick={handleItemClick}
					radius={radius}
					getCirlce={setCircle}
					setCenter={setCenter}
					items={items}
					addedPlacement={addedPlacements}
					itemsCount={placementsInDistance.length}
					isFooterDisabled={false}
					onSearchStringChange={handleSearchStringChange}
					isPlacementsTheSame={isPlacementsTheSame}
					onAddPlacements={movePlacementsToAnotherList}
					setDetailedPlacement={setDetailedPlacement}
					detailedPlacement={detailedPlacement}
					onPlacementClick={fetchDetailedPlacement}
					placements={placementsForRender}
					onRadiusChange={setRadius}
					onRemoveItem={handleRemovePlacement}
					onSubmit={handleSubmit}
					onRemoveAll={() => setAddePlacements([])}
				/>
			) : (
				<Modal
					show={open}
					onHide={onClose}
					centered
					fullscreen
					animation={true}
				>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} className={'ms-2'}>
							Добавление рекламных мест для наружной рекламы
						</Modal.Title>
					</Modal.Header>

					<Modal.Body
						className={'p-0'}
						style={{
							position: 'relative',
							maxWidth: '100vw',
							overflow: 'hidden',
						}}
					>
						<MassCoverLeftPannel
							onAddressClick={handleAddressClick}
							searchAddresses={searchAddresses}
							onAddItem={handleAddPlacement}
							onRemoveItem={handleRemovePlacement}
							addedPlacement={addedPlacements}
							itemsCount={placementsInDistance.length}
							isFooterDisabled={false}
							onSearchStringChange={handleSearchStringChange}
							isPlacementsTheSame={isPlacementsTheSame}
							onAddPlacements={movePlacementsToAnotherList}
							setDetailedPlacement={setDetailedPlacement}
							detailedPlacement={detailedPlacement}
							onPlacementClick={fetchDetailedPlacement}
							placements={placementsForRender}
							onRadiusChange={setRadius}
							radius={radius}
						/>
						<MassCoverageYandexMap
							focusCenter={focusCenter}
							onItemClick={handleItemClick}
							objectManagerJSON={objectManagerJSON}
							center={center}
							radius={radius}
							getCirlce={setCircle}
							setCenter={setCenter}
							items={items}
						/>
						<MassCoverageRightPanel
							isExpanded={isRigthPanelExpanded}
							setIsExpanded={setIsRightPanelExpanded}
							onRemoveItem={handleRemovePlacement}
							onSubmit={handleSubmit}
							onRemoveAll={() => setAddePlacements([])}
							addedPlacement={addedPlacements}
						/>
					</Modal.Body>
				</Modal>
			)}
		</>
	)
}
