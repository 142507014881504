import React, { useEffect, useMemo, useState } from 'react'
import useCampaignData from 'src/stores/campaignData'
import useResponsive from 'src/utils/useResponsive'
import s from '../../bookings/BookingAd.module.scss'
import { MessagesData } from '../../constants/messages-data'
import { Button } from 'react-bootstrap'
import { StateView } from './StateView'
import ProgressFileInput from 'src/components/UtilityComponents/ProgressFileInput'
import { AdstreamActionButtons } from '../../tv/adstream/adstream-action-buttons/AdstreamActionButtons'
import { MoscowCentRingRequirementsModal } from './modals/MoscowCentRingRequirementsModal'
import { MoscowCentRingEditModal } from './modals/MoscowCentRingEditModal'
import { useConfirm } from 'src/components/UtilityComponents/ConfirmModalProvider'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'
import { InternetState } from 'src/stores/ADMarketTypes.types'
import SmallBottomSheet from 'src/components/UtilityComponents/SmallBottomSheet'
import { TvItemHeader } from '../../tv/common/TvItemHeader'
import IntlFormatter from 'src/utils/intl-money-formatter'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantProcessing } from '../../common/edit-vinstant-processing/EditVinstantProcessing'
import { useCampaignInfoStore } from '../../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantModal } from '../../common/edit-vinstant-modal/EditVinstantModal'
import { InputExtraButtons } from 'src/components/_client/create-campaign/Step3-Upload/components/outdoor/common/input-extra-buttons/InputExtraButtons'
import { downloadByRequest } from 'src/utils'

export const MoscowCentRingInfo = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()
	const data = MessagesData[campaign.public_transport!.state]

	const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [requirementsModalOpen, setRequirementsModalOpen] = useState(false)
	const { confirm, onCancel } = useConfirm()

	const isDeleteButton = useMemo(() => {
		return (
			campaign.public_transport?.state !== InternetState.Reject &&
			campaign.public_transport?.state !== InternetState.Canceled &&
			campaign.public_transport?.state !== InternetState.Completed
		)
	}, [campaign])

	const media =
		campaign.public_transport?.image || campaign.public_transport?.video
	const { isMobile } = useResponsive()
	const handleDelete = async () => {
		setRequirementsModalOpen(false)
		setEditModalOpen(false)

		const answ = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Вы уверены, что хотите удалить размещение на Цифровых экранах МЦК ?',
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (!answ) return

		await authFetch({
			url: `${BASE_URL}/adv_companies/${campaign.id}/transport-adv/`,
			method: 'PATCH',
			body: {
				state: InternetState.Canceled,
			},
		})
		await campaignInterface.refetchSelected()
		setIsOpenBottomSheet(false)
		onCancel()
	}
	const handleOpenRequirementsOnMobile = () => {
		setIsOpenBottomSheet(false)
		setRequirementsModalOpen(true)
	}

	const handleEditModalOpen = () => {
		if (campaign.public_transport?.state === InternetState.Adjustment) {
			setEditModalOpen(true)
		}
	}

	const [_, notificationsInterface] = useNotificationData()

	const publicTransportVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'public_transport_id',
			itemId: campaign.public_transport?.id?.toString() || '',
		})
	)
	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated('publicTransport')

	const [isEditVinstantOpen, setIsEditVinstantOpen] = useState(false)

	const handleEditVinstantClick = () => {
		setIsEditVinstantOpen(true)
	}

	const handleVinstantEditEnd = async () => {
		notificationsInterface.setNewContendCreated(
			'publicTransport',
			'pending'
		)
		await campaignInterface.refetchSelected()
	}
	const handleUpload = () => {
		if (media) {
			downloadByRequest(
				`adv_companies/${campaign.id}/transport_creative/download/`,
				media
			)
		}
	}
	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			handleVinstantEditEnd()
		}
	}, [editCreativeVinstantStatus])
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
			<div
				className={s.bookingCard}
				style={{
					flexDirection: 'column',
					padding: isMobile ? '16px' : '40px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
						paddingBottom: '24px',
					}}
				>
					<TvItemHeader
						iconType="public_transport"
						onMenuClick={() =>
							setIsOpenBottomSheet(!isOpenBottomSheet)
						}
						headerText={'Цифровые экраны на МЦК'}
						price={campaign.public_transport?.amount || null}
						isAdstream={false}
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: isMobile ? 'column-reverse' : 'row',
							alignItems: 'flex-start',
							color: data?.color || '',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							{data && <StateView />}
							{/* {campaign.public_transport?.state === InternetState.Adjustment*/}

							<Button
								disabled={
									campaign.public_transport?.state !==
									InternetState.Adjustment
								}
								variant={'primary'}
								className={'mt-3'}
								style={{
									width: isMobile ? '100%' : 'fit-content',
								}}
								onClick={handleEditModalOpen}
							>
								Исправить
							</Button>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								minWidth: '50%',
								width: '100%',
								height: '100%',
							}}
						>
							{editCreativeVinstantStatus === 'start' ? (
								<EditVinstantProcessing />
							) : (
								<ProgressFileInput
									ExtraButton={
										<InputExtraButtons
											onEditVinstant={undefined}
											onUpload={handleUpload}
										/>
									}
									style={{
										width: isMobile ? '100%' : '55%',
									}}
									initialValue={{
										src:
											media ||
											'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
										name: campaign.public_transport?.image
											? 'Изображение'
											: 'Видео',
									}}
									updateUrl={''}
									nameInBody={''}
									onlyView
								/>
							)}

							{!isMobile && (
								<div style={{ paddingLeft: '1rem' }}>
									<AdstreamActionButtons
										isEditVinstantButton={false}
										onEditVinstantClick={() =>
											setEditModalOpen(true)
										}
										requirementButtonText={
											'Требования к файлу'
										}
										isDeleteButton={isDeleteButton}
										onRequirementsClick={() =>
											setRequirementsModalOpen(true)
										}
										onDeleteClick={handleDelete}
									/>
								</div>
							)}
						</div>
					</div>
				</div>

				<EditVinstantModal
					newContentKey={'publicTransport'}
					editVinstantLink={publicTransportVinstantLink}
					onClose={() => setIsEditVinstantOpen(false)}
					open={isEditVinstantOpen}
				/>

				{isOpenBottomSheet && (
					<SmallBottomSheet
						touchHeight="1rem"
						show={isOpenBottomSheet}
						height={isDeleteButton ? '116px' : '64px'}
						onHide={() => setIsOpenBottomSheet(false)}
					>
						<div style={{ padding: '12px' }}>
							<AdstreamActionButtons
								isEditVinstantButton={false}
								onEditVinstantClick={() =>
									setIsEditVinstantOpen(true)
								}
								requirementButtonText={
									'Требования к видеофайлу'
								}
								isDeleteButton={isDeleteButton}
								onDeleteClick={handleDelete}
								onRequirementsClick={
									handleOpenRequirementsOnMobile
								}
							/>
						</div>
					</SmallBottomSheet>
				)}

				{editModalOpen && (
					<MoscowCentRingEditModal
						onEditVinstantClick={handleEditVinstantClick}
						open={editModalOpen}
						onClose={() => setEditModalOpen(false)}
					/>
				)}

				{requirementsModalOpen && (
					<MoscowCentRingRequirementsModal
						open={requirementsModalOpen}
						onClose={() => setRequirementsModalOpen(false)}
					/>
				)}
			</div>
			<div className={s.bookingBudget}>
				<>
					<h5>Всего за общественный транспорт:</h5>
					<h5>{IntlFormatter.format(campaign.budget.transport)}</h5>
				</>
			</div>
		</div>
	)
}
