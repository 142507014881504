import { BookingState } from 'src/stores/ADMarketTypes.types'

export const getAdjustmentMessage = (
	state: BookingState,
	adjusmentMessage: string,
	rejectMessage: string
) => {
	if (state === BookingState.Reject) {
		return rejectMessage
	}
	if (state === BookingState.Adjustment) {
		return adjusmentMessage
	}

	return ''
}
