import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { ReactComponent as XLSIcon } from 'src/assets/images/xls.svg'
import { ReactComponent as DeleteIcon } from 'src/assets/images/delete.svg'
import { ReactComponent as PDF } from 'src/assets/images/pdf.svg'
import s from '../ClientStatistics.module.scss'
import { authFetch } from 'src/utils/authFetch'
import downloadBlob from 'src/utils/downloadBlob'
import SmallBottomSheet from 'src/components/UtilityComponents/SmallBottomSheet'
import { MobileStatisticItem } from './MobileStatisticItem'

export const ClientStatisticsMobile = ({
	setCampaignIdForDocumnents,
	campaignIdForDocumnents,
	oneData,
	handleCampaignClick,
	exportToExcel,
}) => {
	const [modalOpen, setModalOpen] = useState(false)
	const [selectedCampaignId, setSelectedCampaignId] = useState(null)

	const handleOpenModal = (campaignId) => {
		setSelectedCampaignId(campaignId)
		setModalOpen(true)
	}

	const closeModal = () => {
		setSelectedCampaignId(null)
		setModalOpen(false)
	}

	return (
		<>
			<div className={s.mobileHeaderLine}></div>
			{oneData.map((item, index) => (
				<MobileStatisticItem
					isBorder={index !== oneData.length - 1}
					key={item.id}
					item={item}
					onCampaignClick={handleCampaignClick}
					onModalOpen={handleOpenModal}
				/>
			))}

			{modalOpen && (
				<MobileModal
					setCampaignIdForDocumnents={setCampaignIdForDocumnents}
					campaignIdForDocumnents={campaignIdForDocumnents}
					open={modalOpen}
					campaign={oneData.find(
						(el) => el.id === selectedCampaignId
					)}
					campaignId={selectedCampaignId}
					closeModal={closeModal}
					exportToExcel={exportToExcel}
				/>
			)}
		</>
	)
}

const MobileModal = ({
	setCampaignIdForDocumnents,
	campaignIdForDocumnents,
	open,
	campaign,
	campaignId,
	closeModal,
	exportToExcel,
}) => {
	const ref = useRef<HTMLDivElement | null>(null)
	const [height, setHeight] = useState(200)
	const download = async () => {
		const answ: Response = await authFetch({
			url: '/core/api/v1/adv_companies/stats/export/',
			method: 'POST',
			raw: true,
			body: {
				id: [campaign?.id],
			},
		})
		if (answ.ok) {
			const blob = await answ.blob()
			downloadBlob(blob, `Статистика ${campaign?.name}`, 'xlsx')
		} else {
			throw new Error('Ошибка при загрузке')
		}
	}

	const handleSetCampaignIdForDocumnents = () => {
		setCampaignIdForDocumnents(campaign.id)
		closeModal()
	}
	useEffect(() => {
		if (ref.current) {
			setHeight(ref.current.clientHeight)
		}
	}, [ref])
	return (
		<SmallBottomSheet
			show={open}
			onHide={closeModal}
			touchHeight={'25px'}
			height={height + 'px'}
		>
			<div className={s.mobileModal} ref={ref}>
				{(campaign?.state === 'active' ||
					campaign?.state === 'completed') && (
					<>
						{campaign?.count_ones_docs > 0 &&
							campaign.state === 'completed' && (
								<div className={s.mobileModalOnDay}>
									<div className={s.mobileModalOnDayFrame}>
										<PDF
											style={{
												width: '16px',
												height: '16px',
												stroke: '#007BFF',
												left: '4.17%',
												right: '6.31%',
												top: '8.33%',
												bottom: '8.33%',
											}}
										/>
										<span
											onClick={
												handleSetCampaignIdForDocumnents
											}
										>
											Выгрузить документы
										</span>
									</div>
								</div>
							)}

						<div className={s.mobileModalOnDay}>
							<div className={s.mobileModalOnDayFrame}>
								<XLSIcon
									style={{
										stroke: '#007BFF',
										left: '4.17%',
										right: '6.31%',
										top: '8.33%',
										bottom: '8.33%',
									}}
								/>
								<span onClick={download}>
									Выгрузить в Excel по дням
								</span>
							</div>
						</div>

						<div className={s.mobileModalOnPeriod}>
							<div className={s.mobileModalOnPeriodFrame}>
								<XLSIcon
									style={{
										stroke: '#007BFF',
										left: '4.17%',
										right: '6.31%',
										top: '8.33%',
										bottom: '8.33%',
									}}
								/>
								<span onClick={() => exportToExcel(campaign)}>
									Выгрузить в Excel за весь период
								</span>
							</div>
						</div>
					</>
				)}

				<div className={s.mobileModalOnDelete}>
					<div className={s.mobileModalOnDeleteFrame}>
						<DeleteIcon
							style={{
								stroke: '#DC3545',
								left: '8.33%',
								right: '8.33%',
								top: '8.33%',
								bottom: '8.33%',
							}}
						/>
						<span>Отменить</span>
					</div>
				</div>
			</div>
		</SmallBottomSheet>
	)
}
