import React from 'react'
import { CustomRange } from 'src/components/UtilityComponents'
import s from './itemsControl.module.scss'

type Props = {
	radius: number
	value: string
	onRadiusChange: (value: number) => void
}
export const RadiusRange = ({ onRadiusChange, radius, value }: Props) => {
	return (
		<div className={s.rangeControl}>
			<p>Радиус вокруг выбранной точки</p>

			<div className={s.rangeControl_slider}>
				<CustomRange
					max={10000}
					min={500}
					value={radius}
					onChange={onRadiusChange}
				/>
				<div className={s.rangeControl_value}>
					<p>{`${value} км`}</p>
				</div>
			</div>
		</div>
	)
}
