import React, { useCallback } from 'react'
import {
	AdvItemState,
	GarantidItem,
} from 'src/stores/technical-requirements-store'
import {
	MessageProperties,
	MessagesData,
	defaultMessageData,
} from 'src/components/_client/campaign-info/constants/messages-data'
import { StateWithMessage } from './StateWithMessage'
import { ReactComponent as DownloadIcon } from 'src/assets/images/download.svg'
import { ReactComponent as EditIcon } from 'src/assets/images/edit.svg'
import s from './garantidItem.module.scss'
import { MediaPreviewExtended } from '../common-media/CommonMedia'
import { Button, ButtonProps } from 'react-bootstrap'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import { downloadByRequest } from 'src/utils'
import { PreviewPlaceholder } from '../common/PreviewPlaceholder'
import { GarantidItemModal } from './GarantidCampaignItem'
import { BidRequestPreview } from '../common/BidRequestPreview'
import cn from 'classnames'

type GarantidCampaignItemStateWithCreativeProps = {
	item: GarantidItem
	screenResolution: string
	isVinstantLoading: boolean
	setModal: (modalType: GarantidItemModal) => void
}

export const GarantidCampaignItemStateWithCreative = ({
	screenResolution,
	isVinstantLoading,
	setModal,
	item,
}: GarantidCampaignItemStateWithCreativeProps) => {
	const { state, media_creative: creative, reject_msg, adjustment_msg } = item

	const editable = state === AdvItemState.Adjustment

	let stateData: MessageProperties = MessagesData[state] || defaultMessageData

	if (state === AdvItemState.Adjustment) {
		stateData.message = adjustment_msg
	}
	if (state === AdvItemState.Reject) {
		stateData.message = reject_msg
	}

	const handleDownloadMedia = useCallback(async () => {
		if (creative) {
			const url = `adv_company_media/${creative.id}/download/`
			await downloadByRequest(url, creative.file_name)
		}
	}, [creative])
	return (
		<div className="d-flex flex-column gap-3">
			<div className={s.stateWithCreative}>
				<StateWithMessage stateData={stateData} />

				{creative ? (
					isVinstantLoading ? (
						<PreviewPlaceholder />
					) : (
						<MediaPreviewExtended
							file={creative.file}
							fileName={creative.file_name}
							state={state}
							screenResolution={screenResolution}
						>
							<div className={s.mediaPreviewExtendedButtons}>
								{editable && (
									<Tooltip
										text="Изменить материал"
										placement="left"
									>
										<Button
											className={s.uploadButton}
											variant={'text'}
											onClick={() => setModal('edit')}
										>
											<EditIcon />
										</Button>
									</Tooltip>
								)}

								<Tooltip
									text="Выгрузить материал"
									placement="left"
								>
									<Button
										className={s.uploadButton}
										variant={'text'}
										onClick={handleDownloadMedia}
									>
										<DownloadIcon />
									</Button>
								</Tooltip>
							</div>
						</MediaPreviewExtended>
					)
				) : (
					<BidRequestPreview />
				)}
			</div>
		</div>
	)
}

type UploadButtonProps = {
	handleDownloadMedia: () => void
	className?: string
	variant?: ButtonProps['variant']
}
export const UploadButton = (props: UploadButtonProps) => {
	return (
		<Tooltip text="Выгрузить материал" placement="left">
			<Button
				className={cn(s.uploadButton, props.className)}
				variant={props.variant || 'text'}
				onClick={props.handleDownloadMedia}
			>
				<DownloadIcon />
			</Button>
		</Tooltip>
	)
}
