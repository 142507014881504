import React, { useEffect, useState } from 'react'
import s from '../outdoor-ad/outdoorAd.module.scss'
import { MoscowCentRing, PublicTransportSelector } from './components'
import { ReactComponent as PublicTransortIcon } from 'src/assets/images/metro.svg'
import useCampaignData from 'src/stores/campaignData'
import { Card } from 'react-bootstrap'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'
import { useAdformatsStore } from '../adformats-store/adformats.store'

export const PublicTransportAd = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()
	const toggleAdFormatSelectedState = useAdformatsStore(
		(state) => state.toggleAdFormatSelectedState
	)
	const mcrIsSelected = useAdformatsStore((state) => state.mcr.isSelected)

	const [selectedSubAdv, setSelectedSubAdv] = useState<
		Array<{ [key: string]: JSX.Element }>
	>(() => {
		const methods = [] as Array<{
			[key: string]: JSX.Element
		}>
		if (campaign.public_transport?.is_selected) {
			methods.push({
				center: (
					<MoscowCentRing
						onError={props.onError}
						setGlobalIsLoading={props.setIsLoading}
						isLoading={false}
						cn={props.cn}
					/>
				),
			})
		}

		return methods
	})

	const toggleIsSelected = async (selectorKey: 'center' | 'diameter') => {
		if (selectorKey === 'diameter') {
			return
		}
		try {
			props.setIsLoading(true)
			const result = await authFetch<{ is_selected: boolean }>({
				method: 'PATCH',
				url: `${BASE_URL}/adv_companies/${campaign.id}/transport-adv/`,
				body: { is_selected: !campaign.public_transport?.is_selected },
			})
			toggleAdFormatSelectedState('mcr', result.is_selected)
			await campaignInterface.refetchSelected()
			props.setIsLoading(false)
			const isNowSelected = selectedSubAdv.some(
				(method) => method[selectorKey]
			)

			if (isNowSelected) {
				const newMethods = selectedSubAdv.filter(
					(method) => Object.keys(method)?.[0] !== selectorKey
				)

				setSelectedSubAdv(newMethods)
			} else {
				if (selectorKey === 'center') {
					setSelectedSubAdv((prev) => [
						{
							center: (
								<MoscowCentRing
									isLoading={false}
									onError={props.onError}
									setGlobalIsLoading={props.setIsLoading}
									cn={props.cn}
								/>
							),
						},
						...prev,
					])
				}
				//на 06.03.2024 исключена возможность добавления МЦД
				// if (selectorKey === 'diameter') {
				// 	setSelectedSubAdv((prev) => [
				// 		{
				// 			diameter: (
				// 				<MoscowCentDiameter
				// 					restProps={{ ...props }}
				// 					cn={props.cn}
				// 				/>
				// 			),
				// 		},
				// 		...prev,
				// 	])
				// }
			}
		} catch (error) {}
	}

	const methods = selectedSubAdv.map(
		(obj) => Object.values(obj)[0]
	) as Array<JSX.Element>

	return (
		<>
			<Card className={props.cn('form')}>
				<div className={s.outdoorCard}>
					<div className={s.outdoorHeader}>
						<div className={s.primaryText}>
							<h4>Общественный транспорт</h4>
							<div>
								<PublicTransortIcon />
							</div>
						</div>
						<p>Выберите способ размещения</p>
					</div>
					<div className={s.selectorsContainer}>
						<PublicTransportSelector
							disabled={false}
							onClick={toggleIsSelected}
							description={
								'64 экрана на 30 станциях Московского Центрального Кольца.'
							}
							extraInfo="Средний пассажиропоток: > 58 млн. в месяц."
							isSelected={mcrIsSelected}
							title="Москва – МЦК"
							selectorKey={'center'}
						/>

						<PublicTransportSelector
							disabled={true}
							onClick={toggleIsSelected}
							extraInfo="Идёт подключение"
							description={
								'Экраны на станциях и в поездах Московских Центральных Диаметров.'
							}
							isSelected={selectedSubAdv.some(
								(method) => method['diameter']
							)}
							title="Москва – МЦД"
							selectorKey={'diameter'}
						/>
					</div>
				</div>
			</Card>
			{methods.length > 0 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: '24px',
						gap: '24px',
					}}
				>
					{methods}
				</div>
			)}
		</>
	)
}
