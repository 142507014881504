import React, { FC, useState } from 'react'
import { BookingDetails } from '../../../../../stores/ProviderTypes.types'
import useBookingsOperations from '../../context/useBookingsOperations'
import { BookingState } from '../../../../../stores/ADMarketTypes.types'
import s from './bookingdetails.module.scss'
import { useBookingsContext } from '../../context/BookingsProvider'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'
import { Button, Modal } from 'react-bootstrap'
import { ActionModal } from './modals'

interface IBookingsButtons {
	booking: BookingDetails
	vertical?: boolean
	onModalClose?: () => void
}

const BookingsButtons: FC<IBookingsButtons> = ({
	booking,
	vertical,
	onModalClose,
}) => {
	const { onActionButtonClick } = useBookingsContext()
	const { buttons } = useBookingsOperations({
		bookingInModeration:
			booking.state === BookingState.Moderation ||
			booking.state === BookingState.Inner_Moderation,
	})
	const { isMobile } = useResponsive()

	const [actionType, setActionType] = useState<
		'accept' | 'edit' | 'reject' | 'download' | undefined
	>(undefined)

	const handleClick = (
		actionType: 'accept' | 'edit' | 'reject' | 'download',
		bookingId: number | Array<number>
	) => {
		if (actionType !== 'download') {
			setActionType(actionType)
		} else {
			onActionButtonClick({
				action: actionType,
				id: bookingId,
				booking: booking,
			})
		}
	}

	return (
		<>
			<div
				className={cn(s.bookingButtonsContainer, {
					[s.vertical]: vertical,
				})}
			>
				{buttons.map((button) => {
					const Icon = button.icon

					return (
						<button
							key={button.actionType}
							className={s.bookingButton}
							style={{ color: button.color }}
							onClick={() =>
								handleClick(button.actionType, booking.id)
							}
						>
							{
								<Icon
									width={vertical ? 12 : void 0}
									height={vertical ? 12 : void 0}
								/>
							}
							<span
								style={{ fontSize: isMobile ? '12px' : '14px' }}
							>
								{button.text}
							</span>
						</button>
					)
				})}
			</div>
			{!!actionType && (
				<ActionModal
					actionType={actionType}
					booking={booking}
					onClose={() => setActionType(undefined)}
					open={!!actionType}
				/>
			)}
		</>
	)
}

export default BookingsButtons

type BookingsButtonsModalFooterProps = {
	action: () => void
	onClose: () => void
	actionType: 'accept' | 'reject'
	callback?: () => void
}

//TODO удалить если нигде не используется
const BookingsButtonsModalFooter = ({
	action,
	onClose,
	actionType,
	callback,
}: BookingsButtonsModalFooterProps) => {
	const { isMobile } = useResponsive()

	const handleClick = () => {
		action()
		onClose()
		callback && callback()
	}
	return (
		<Modal.Footer>
			<Button variant="secondary" onClick={onClose}>
				Отменить
			</Button>
			<Button variant="primary" onClick={handleClick}>
				{actionType === 'accept' ? 'Да, подтверждаю' : 'Отклонить'}
			</Button>
		</Modal.Footer>
	)
}
