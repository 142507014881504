import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Dropdown, Spinner } from 'react-bootstrap'
import useResponsive from '../../../../utils/useResponsive'
import useCampaignData from '../../../../stores/campaignData'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import useBidRequest from './CreateCreative/useBidRequest'

const UploadStatus = ({ availableSteps, currentStep, s, setStep }) => {
	const showModal = useReusableModals()
	const [loading, setLoading] = useState(false)
	const filteredSteps = availableSteps.filter(
		(_, index) => index < currentStep
	)
	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (loading) {
			timeoutId = setTimeout(() => {
				setLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [loading])

	if (filteredSteps.length === 0) return null

	const handleMediaPlanClick = async () => {
		setLoading(true) // Показать спиннер загрузки перед открытием модального окна
		await showModal(REUSABLE_MODALS.showMediaPlanModal)
		setLoading(false) // Скрыть спиннер загрузки после закрытия модального окна
	}

	return (
		<Card className={s.card}>
			<h2 style={{ marginBottom: '0px' }}>Загрузка материалов</h2>
			<Dropdown.Divider
				className={' p-0'}
				style={{ marginBottom: '24px' }}
			/>
			<Button
				variant={'light'}
				onClick={handleMediaPlanClick}
				className={'w-100 mb-4'}
				disabled={loading}
			>
				{loading ? (
					<>
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
							className="me-2"
						/>
						Загрузка медиаплана...
					</>
				) : (
					'Смотреть медиаплан'
				)}
			</Button>
			{filteredSteps.map((step, index) => (
				<React.Fragment key={step.label}>
					<DrawStep step={step} index={index} setStep={setStep} />
					{index !== filteredSteps.length - 1 && (
						<Dropdown.Divider
							className={' p-0'}
							style={{ marginBottom: '16px', marginTop: '24px' }}
						/>
					)}
				</React.Fragment>
			))}
		</Card>
	)
}

const DrawStep = ({ step, index, setStep }) => {
	const { isMobile } = useResponsive()
	const [campaign] = useCampaignData()

	const { isHaveBidRequest } = useBidRequest()
	const isMediaUpload = useMemo(() => {
		const allBookingMediaIds = [
			...campaign.media.map((el) => el.booking_id),
			...(campaign.bid_requests.booking?.map((el) => el.id) || []),
		]

		if (step.label === 'Наружная реклама') {
			const isOutDoorMedia = campaign.outdoor_adv_is_selected
				? campaign.outdoor_bookings.every((el) =>
						allBookingMediaIds.includes(el.id)
				  )
				: true

			const districtBookingIdsWithCreative = campaign.districtbookings
				.filter((el) => el.creatives.length !== 0)
				.map((el) => el.id)

			const districtBookingBids =
				campaign.bid_requests.districtbooking?.map((el) => el.id) || []
			const allDistrictBookingMediaIds = [
				...districtBookingBids,
				...districtBookingIdsWithCreative,
			]

			const isDistrictBookingMedia = campaign.district_adv_is_selected
				? campaign.districtbookings.every((el) =>
						allDistrictBookingMediaIds.includes(el.id)
				  )
				: true

			return isOutDoorMedia && isDistrictBookingMedia
		}
		if (step.label === 'Внутренняя реклама') {
			const isIndoorDoorMedia = campaign.indoor_bookings.every((el) =>
				allBookingMediaIds.includes(el.id)
			)
			return isIndoorDoorMedia
		}
		if (step.label === 'Интернет-реклама') {
			return (
				(campaign.internet_vk.is_selected
					? campaign.internet_vk.image ||
					  isHaveBidRequest({
							type: 'internet_vk',
							id: campaign.internet_vk.id,
					  })
					: true) &&
				(campaign.internet_yandex.is_selected
					? campaign.internet_yandex.image ||
					  isHaveBidRequest({
							type: 'internet_yandex',
							id: campaign.internet_yandex.id,
					  })
					: true)
			)
		}
		if (step.label === 'Общественный транспорт') {
			return (
				campaign.public_transport?.image ||
				campaign.public_transport?.video ||
				isHaveBidRequest({
					id: campaign.public_transport?.id || 0,
					type: 'public_transport',
				})
			)
		}
		if (step.label === 'ТВ') {
			return (
				campaign.adstream.image ||
				isHaveBidRequest({ type: 'adstream', id: campaign.adstream.id })
			)
		}
		return null
	}, [step, campaign]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={'d-flex flex-column'}>
			<div
				className={
					'd-flex justify-content-between align-items-center mb-1'
				}
			>
				<h4 className={'mb-0'}>{step.label}</h4>
				<div>
					<Button
						variant={'secondary'}
						onClick={() => {
							setStep(index)
						}}
						size={isMobile ? 'sm' : undefined}
						className={'text-nowrap'}
					>
						<i className="bi bi-pencil me-2" />
						Изменить
					</Button>
				</div>
			</div>
			<span className={isMediaUpload ? 'text-success' : 'text-danger'}>
				{isMediaUpload ? (
					<>
						<i className={'bi bi-check me-2'}></i>Все материалы
						загружены
					</>
				) : (
					'Материалы не sdfdsf загружены'
				)}
			</span>
		</div>
	)
}

export default UploadStatus
