import React from 'react'
import ls from '../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { ReactComponent as Indoor } from '../../../../../../../../assets/images/indoor.svg'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { AdvItemStatistic } from '../common/AdvItemStatistic'
import { IndoorAccordionBody } from './accorion-bodies/IndoorAccordionBody'
import { CommonAdvAccordionsProps } from '../CampaignDetailedInfo'
import { getAdjustmentMessage } from 'src/components/_moderator/utils'

export const IndoorDetailedAccordion = ({
	setDetailedItem,
	setAdvItemActon,
	onEditVinstant,
}: CommonAdvAccordionsProps) => {
	const [moder, moderInterface] = useModerationData()

	const indoorBookings = moder?.detailedCampaign?.booking?.filter(
		(booking) => booking.placement.door_type === 'indoor'
	)

	const totalPrice = indoorBookings.reduce(
		(acc, current) =>
			acc + parseFloat(current.placement_showing_count.price),
		0
	)

	const showsTotal = indoorBookings.reduce(
		(acc, current) =>
			acc + current.placement_showing_count.showing_count_per_day,
		0
	)

	return (
		<Accordion className={ls.accordion} defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header as={'div'} className={ls.accordionHeader}>
					<Indoor style={{ fill: '#212529' }} />{' '}
					<h6>Внутренняя реклама</h6>
				</Accordion.Header>

				{!!indoorBookings.length &&
					indoorBookings.map((booking) => (
						<IndoorAccordionBody
							onEditVinstant={onEditVinstant}
							adjusmentMessage={getAdjustmentMessage(
								booking.state,
								booking.adjustment_msg,
								booking.reject_msg
							)}
							setAdvItemActon={setAdvItemActon}
							setDetailedItem={setDetailedItem}
							key={booking.id}
							booking={booking}
						/>
					))}

				<Accordion.Body style={{ padding: '0 8px 0 16px' }}>
					<AdvItemStatistic
						style={{ marginTop: 0 }}
						price={totalPrice}
						shows={showsTotal}
						total={indoorBookings.length}
					/>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}
