import React, { FC, useMemo, useState } from 'react'
import useCampaignData from 'src/stores/campaignData'
import { Button, Card, Dropdown, Modal, Spinner } from 'react-bootstrap'
import { AdIcon } from '../../../Step2-ADformats/AdFormatSelector'
import s from '../../DataUpload.module.scss'

import useResponsive from 'src/utils/useResponsive'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from 'src/components/UtilityComponents/ReusableModals/ReusableModalsProvider'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import { DistrictBookingsUploadByFormats } from '../outdoor/districts/DistrictBookingsUploadByFormats'
import { BookingsUploadByFormats } from '../outdoor/bookings/BookingsUploadByFormats'

export const OutDoorUpload: FC<any> = (props) => {
	const { isLoading, onBack, onNext } = props
	const [isMediaPlanLoading, setIsMediaPlanLoading] = useState(false)
	const showModal = useReusableModals()
	const [campaign] = useCampaignData()

	const district = useTechnicalRequirementsStore((state) => state.district)
	const outdoor = useTechnicalRequirementsStore((state) => state.outdoor)

	const isContinueDisabled = useMemo<boolean>(() => {
		const isEveryDistrictsMediaFormatsHasMedia = district
			.flatMap((district) => district.advFormats)
			.every((format) => format.media?.file)

		const isEveryOutdoorBookingsHasMedia = outdoor
			.flatMap((el) => el.booking_item)
			.every((booking) => booking.item.media_creative?.file)

		return (
			!isEveryDistrictsMediaFormatsHasMedia ||
			!isEveryOutdoorBookingsHasMedia
		)
	}, [district, outdoor])

	const handleMediaPlanClick = async () => {
		await showModal(REUSABLE_MODALS.showMediaPlanModal)
	}
	const { isMobile } = useResponsive()

	return (
		<div>
			<Card className={s.card} style={{ marginBottom: '8px' }}>
				<h2 style={{ marginBottom: '8px' }}>Загрузка материалов</h2>
				<Dropdown.Divider className={' p-0 m-0 mb-4'} />
				<Button
					variant={'light'}
					className={'mb-3'}
					onClick={handleMediaPlanClick}
					disabled={isMediaPlanLoading}
				>
					{isMediaPlanLoading ? (
						<>
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
								className="me-2"
							/>
							Загрузка медиаплана...
						</>
					) : (
						'Смотреть медиаплан'
					)}
				</Button>
				<div className={'d-flex justify-content-between mb-2'}>
					<h4 className={'mb-0'}>Наружная реклама</h4>
					<AdIcon name={'outdoor'} />
				</div>

				<p className={'mb-0'}>
					Загрузите материалы к каждому способу размещения в
					соответствии с техническими требованиями
				</p>
			</Card>
			<div className="d-flex flex-column gap-4">
				{campaign.district_adv_is_selected &&
					!!campaign.districtbookings.length && (
						<DistrictBookingsUploadByFormats />
					)}

				{campaign.outdoor_adv_is_selected &&
					!!campaign.outdoor_bookings.length && (
						<BookingsUploadByFormats advType="outdoor" />
					)}
			</div>

			<div className={props.cn('buttons')}>
				<Button
					variant={'primary'}
					onClick={onNext}
					style={
						isMobile ? { width: '100%', marginLeft: '10px' } : {}
					}
					disabled={props.isLoading || isContinueDisabled}
				>
					{/* {isLastStep ? 'Последний шаг' : 'Продолжить'} */}
					Продолжить
					{isLoading && (
						<Spinner
							style={{ marginLeft: '6px' }}
							size="sm"
							animation="border"
						/>
					)}
				</Button>
				<Button variant={'secondary'} onClick={onBack}>
					Назад
				</Button>
			</div>
		</div>
	)
}
