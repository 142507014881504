import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import BookingAD from '../../../BookingsAD/BookingAD'
import { ReactComponent as Target } from 'src/assets/images/location-3.svg'
import useCampaignData from 'src/stores/campaignData'
import { useAdformatsStore } from '../../../adformats-store/adformats.store'

export const OutdoorLocationMethod = ({
	title,
	cn,
	...restProps
}: {
	title: string
	cn: (className: string) => string
	restProps: any
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const outdoorAdIsSelected = campaign.outdoor_adv_is_selected

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)

	useEffect(() => {
		if (outdoorAdIsSelected) {
			if (
				campaign.booking.filter(
					(el) => el.placement.door_type === 'outdoor'
				).length <= 0
			) {
				addNewAdFormatError('garant', {
					field: 'Не добавлены места',
					id: 'garant_id',
					message: 'Не добавлены места',
				})
			} else {
				setAdFormatErrors('garant', null)
			}
		} else {
			setAdFormatErrors('garant', null)
		}
	}, [campaign, outdoorAdIsSelected])

	return (
		<Card className={cn('form')} id={'garant_id'}>
			<div
				className={'d-flex justify-content-between'}
				style={{ marginBottom: '8px' }}
			>
				<h4 className={'mb-0'}>{title}</h4>
				<div>
					<Target />
				</div>
			</div>
			<div className={'mb-3'}>
				Бронируются выбранные рекламные места с гарантированным числом
				показов в сутки и фиксированной ценой на весь период кампании
			</div>

			<BookingAD
				isHeader={false}
				isHiddenContent={false}
				onError={() => {}}
				setIsLoading={() => {}}
				bookings_type="outdoor"
			/>
		</Card>
	)
}
