import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import useCampaignData from '../../../../../stores/campaignData'
import { Col, Form, Row } from 'react-bootstrap'
import { VkIcon } from './InternetSelector'
import FormLabelTooltip from '../../../../UtilityComponents/FormLabelTooltip'
import { InternetTooltips } from './InternetTooltips'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'
import { addHttps, checkInternetURL } from './URLutils'
import { FormatConfExact } from '../../components'
import { InsideErrors } from '../ADFormatSummary'
import useDebounce from 'src/utils/useDebounce'
import { NumericFormat } from 'react-number-format'
import { useAdformatsStore } from '../adformats-store/adformats.store'

interface IVkAd {
	children?: React.ReactNode | React.ReactNode[]
	setIsLoading: (value: boolean) => void
}

type VkAdFields = {
	link: string
	amount: number
}
type VkAdFieldsErrors = {
	link: string | undefined
	amount: string | undefined
}

const vkAmountId = 'vk_amount'
const vkLinkId = 'vk_link'

const VkAd: FC<IVkAd> = ({ setIsLoading }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [displayAll, setDisplayAll] = useState(true)

	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)
	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)

	const isVkSelected = useAdformatsStore((state) => state.vk.isSelected)

	const actualAmount = useMemo(() => {
		return campaign.internet_vk.amount
	}, [campaign])
	const actualLink = useMemo(() => {
		return campaign.internet_vk.link
	}, [campaign])
	const minBudget = useMemo(() => {
		return campaign.internet_vk.min_budget || 0
	}, [campaign])

	const [amount, setAmount] = useState(
		campaign.internet_vk?.amount
			? campaign.internet_vk?.amount.toString()
			: ''
	)
	const [link, setLink] = useState(campaign.internet_vk.link || '')
	const [errors, setErrors] = useState<VkAdFieldsErrors>(() => ({
		amount:
			parseFloat(amount) < minBudget
				? `Минимальный бюджет ${minBudget} ₽`
				: undefined,
		link: link ? undefined : 'Добавьте ссылку',
	}))

	const debouncedAmount = useDebounce(amount, 300)
	const debouncedLink = useDebounce(link, 300)

	const handleValidate = useCallback(
		(field: keyof VkAdFields, value: string | number) => {
			const validateErrors: VkAdFieldsErrors = {
				...errors,
			}

			if (field === 'link') {
				if (!value) {
					validateErrors.link = 'Добавьте ссылку'
				} else if (!checkInternetURL(value as string)) {
					validateErrors.link = 'Некорректная ссылка'
				} else {
					validateErrors.link = undefined
				}
			}
			if (field === 'amount') {
				const validValue = parseFloat(value as string)

				if (value === '') {
					validateErrors.amount = 'Добавьте бюджет'
				} else if (isNaN(validValue)) {
					validateErrors.amount = 'Некорректное значение'
				} else if (validValue < minBudget) {
					validateErrors.amount = `Минимальный бюджет ${minBudget} ₽`
				} else {
					validateErrors.amount = undefined
				}
			}
			return validateErrors
		},
		[errors, minBudget]
	)
	const updateVk = useCallback(
		async (field: keyof VkAdFields, value: string | number) => {
			const validationResult = handleValidate(field, value)
			if (validationResult[field]) {
				setErrors(validationResult)
				return
			}
			const formData = new FormData()
			if (field === 'link' && value !== link) {
				const linkWithProtocol = addHttps(value as string)
				formData.append(field, linkWithProtocol)
			}
			if (field === 'amount' && value !== amount) {
				formData.append(field, value.toString())
			}
			try {
				setIsLoading(true)
				await campaignInterface.patchInternetVk(
					campaign.id,
					formData,
					false
				)
				const disableErrors: VkAdFieldsErrors = {
					...errors,
				}
				if (field === 'amount') {
					disableErrors.amount = undefined
				}
				if (field === 'link') {
					disableErrors.link = undefined
				}
				setErrors(disableErrors)
				await campaignInterface.refetchSelected()
			} catch (error: any) {
				const validateErrors = { ...errors }
				if (error['link'])
					validateErrors.link = error['link']?.join(',')
				if (error['amount'])
					validateErrors.amount = error['amount']?.join(',')
				setErrors(validateErrors)
			} finally {
				setIsLoading(false)
			}
		},
		[errors, minBudget]
	)

	const handleAmountChange = useCallback((value: string) => {
		const newAmount = value?.replaceAll(' ', '')?.replace('₽', '')

		setAmount(newAmount)
	}, [])

	const handleLinkChange = useCallback((value: string) => {
		setLink(value.trim())
	}, [])

	useEffect(() => {
		if (actualLink !== link) {
			updateVk('link', debouncedLink)
		} else {
			const validateErrors = handleValidate('link', link)
			setErrors(validateErrors)
		}
	}, [debouncedLink, actualLink])
	useEffect(() => {
		if (actualAmount !== parseFloat(amount)) {
			updateVk('amount', debouncedAmount)
		} else {
			const validateErrors = handleValidate('amount', amount)
			setErrors(validateErrors)
		}
	}, [debouncedAmount, actualAmount])

	useEffect(() => {
		if (isVkSelected) {
			setAmount(
				campaign.internet_vk?.amount
					? campaign.internet_vk?.amount.toString()
					: ''
			)
		}
	}, [isVkSelected])

	useEffect(() => {
		if (isVkSelected) {
			if (!!errors.amount) {
				addNewAdFormatError('vk', {
					field: 'Бюджет',
					id: vkAmountId,
					message: errors.amount || 'Ошибка в бюджете',
				})
			} else {
				removeAdFormatErrorByErrorId('vk', vkAmountId)
			}
			if (!!errors.link) {
				addNewAdFormatError('vk', {
					field: 'Сайт',
					id: vkLinkId,
					message: errors.link || 'Проверьте адрес сайта',
				})
			} else {
				removeAdFormatErrorByErrorId('vk', vkLinkId)
			}
		}
	}, [errors, isVkSelected])

	return (
		<Form className={'d-flex flex-column gap-4'}>
			<div className={'d-flex justify-content-between'}>
				<h5 className={'mb-0'}>VK Реклама</h5>
				<div>
					<VkIcon />
				</div>
			</div>
			<Form.Group>
				<ul className={'d-flex flex-column gap-2'}>
					<li>
						Вам понадобится рекламное изображение. Если у вас его
						нет — мы поможем с изготовлением. Соответствующая опция
						появится на следующем этапе «Загрузка материалов».
					</li>

					<li>
						Аудитория увидит ваше рекламу в социальных сетях
						ВКонтакте и Одноклассники; мобильных и десктопных
						сервисах и контентных проектах компании VK; собственной
						рекламной сети VK, куда входят сайты и приложения; сети
						NativeRoll. Полный список площадок Полный список
						площадок, вы можете посмотреть в специальном разделе на
						сайте{' '}
						<a
							href="https://target.my.com/pro/education/online-course/creating-ad-campaign/placements"
							target={'_blank'}
							rel={'noreferrer'}
						>
							VK.
						</a>
					</li>
					<li>
						Минимальная стоимость рекламной кампании напрямую
						зависит от выбранного периода (количества дней).
					</li>
				</ul>
			</Form.Group>
			<Form.Group>
				<Form.Label>Рекламируемый сайт</Form.Label>
				<Form.Control
					id={vkLinkId}
					type={'text'}
					size={'lg'}
					value={link}
					onChange={(e) => handleLinkChange(e.target.value)}
					isInvalid={!!errors.link}
					placeholder={'Введите ссылку на сайт'}
				/>
				<Form.Control.Feedback type={'invalid'}>
					{errors.link}
				</Form.Control.Feedback>
			</Form.Group>
			{displayAll && (
				<>
					<Form.Group>
						<Form.Label>Целевое действие</Form.Label>
						<Form.Control
							type={'text'}
							value={'Клики по рекламе'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Стратегия
							<FormLabelTooltip
								text={InternetTooltips.VK.strategy}
							/>
						</Form.Label>
						<Form.Control
							type={'text'}
							value={'Максимум показов по минимальной цене'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Бюджет кампании</Form.Label>

							<NumericFormat
								id={vkAmountId}
								decimalScale={0}
								value={amount}
								suffix=" ₽"
								thousandSeparator={' '}
								customInput={InputWithEndText}
								onChange={(e) =>
									handleAmountChange(e.target.value)
								}
								size={'lg'}
								isInvalid={!!errors.amount}
								FeedBackComponent={
									<Form.Control.Feedback type="invalid">
										{errors.amount}
									</Form.Control.Feedback>
								}
							/>
						</Form.Group>
						<Form.Group as={Col}></Form.Group>
					</Row>
					<Form.Group>
						<FormatConfExact
							isValid={!errors.amount && !errors.link}
						/>
					</Form.Group>
				</>
			)}
		</Form>
	)
}

export default VkAd
